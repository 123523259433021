import React, { useEffect, useState } from 'react';
import styles from '../../styles/admin.module.css';
import Notification from '../misc/Notification';
import TextInput from '../inputs/TextInput';
import useForm from '../../hooks/useForm';
import useNotification from '../../hooks/useNotification';
import SaveButton from '../misc/SaveButton';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import validators from '../../validators/userValidators';
import authService from '../../services/authService';

function FormSentResponse({ email }: { email: string }) {
  return (
    <div className={styles.formWrapper} style={{ minHeight: '50vh' }}>
      <div className={styles.form}>
        <h3 className={styles.loginHeader}>
          Linkki salasanan vaihtoon on lähetetty osoitteeseen
          <br />
          <br />
          <b>{email}</b>
        </h3>
        <p className={styles.loginHeader}>
          Tarkista sähköpostisi ja seuraa siellä olevia ohjeita salasanan vaihtamiseksi.
        </p>
      </div>
    </div>
  );
}

function RequestPasswordReset() {
  const { notification, showNotification, notificationType } = useNotification();
  const [formSent, setFormSent] = useState(false);
  const responseHandler = (res: any) => {
    try {
      const { success, error } = res;
      if (success) {
        setFormSent(true);
      } else {
        showNotification(error ?? 'Käyttäjää tällä sähköpostilla ei löytynyt', 'error');
      }
    } catch (e) {
      showNotification('Jokin meni pieleen', 'error');
    }
  };

  const {
    updateField, submit, errors, isLoading, values,
  } = useForm({
    validator: validators.validatePasswordReset,
    submitter: authService.requestPasswordReset,
    responseHandler,
  });

  useEffect(() => {
    const hasError = Object.values(errors).reduce((p, n) => p || n, false);
    if (hasError) showNotification('Tarkista puuttuvat kentät', 'error');
  }, [errors]);

  const windowDims = useWindowDimensions();
  const isMobile = windowDims.width < 600;

  if (formSent) {
    return <FormSentResponse email={values.email} />;
  }

  return (
    <div className={styles.formWrapper}>
      {notification && <Notification message={notification} type={notificationType} /> }
      <form
        className={styles.form}
        style={{ maxWidth: 400 }}
        onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
      >
        <h3 className={styles.loginHeader}>Pyydä salasanan vaihtoa</h3>
        <p className={styles.loginHeader}>
          Saat sähköpostitse linkin, jonka kautta voit vaihtaa salasanasi uuteen.
        </p>
        <TextInput
          label="Sähköposti"
          placeholder="Sähköposti (jolla olet rekisteröitynyt palveluun)"
          setValue={updateField('email')}
          defaultValue={values.email}
          error={errors.email}
          white={isMobile}
          className="email-input"
        />
        <SaveButton
          text="Pyydä salasanan vaihtoa"
          size="medium"
          variant="contained"
          onClick={submit}
          isLoading={isLoading}
          type="submit"
        />
      </form>
    </div>
  );
}

export default RequestPasswordReset;
