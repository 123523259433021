import axios from 'axios';

const API_URL = '/api/payments';

const validatePayment = async (orderNumber: String) => {
  const response = await axios.get(`${API_URL}/validate-payment/${orderNumber}`);
  return response.data;
};

export default {
  validatePayment,
};
