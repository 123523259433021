import React, { useEffect, useRef, useState } from 'react';
import {
  DataGrid,
  useGridApiRef,
} from '@mui/x-data-grid';
import { Button, ToggleButton, Typography } from '@mui/material';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import registerService from '../../services/registerService';
import styles from '../../styles/admin.module.css';
import useNotification from '../../hooks/useNotification';
import { Dog, FullSearchControlParams } from '../../types';
import Notification from '../misc/Notification';
import dataGridCols from './dataGridCols';
import TextInput from '../inputs/TextInput';
import SearchControls from '../register/listing/SearchControls';
import useAutocomplete from '../../hooks/useAutocomplete';
import DogAutocompleteSearch from '../inputs/DogAutocompleteSearch';

function DeleteDogForm({
  dog, onSubmit, onCancel,
}: {
  dog: Dog,
  onSubmit: (dogId: string) => void,
  onCancel: () => void }) {
  return (
    <div style={{
      position: 'fixed',
      width: '500px',
      height: 'auto',
      top: 'calc(50% - 150px)',
      left: 'calc(50% - 250px)',
      background: 'white',
      padding: '20px',
      borderRadius: '5px',
      boxShadow: '0 0 10px rgba(0,0,0,0.5)',
      zIndex: 1000,
    }}
    >
      <Typography style={{ marginBottom: '20px' }}>
        Oletko varma, että haluat poistaa koiran
        {' '}
        {dog.name}
        ?
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        <Button
          onClick={() => onSubmit(dog.id.toString())}
          variant="contained"
          color="error"
        >
          Poista
        </Button>
        <Button
          onClick={onCancel}
          variant="outlined"
        >
          Peruuta
        </Button>
      </div>
    </div>
  );
}

function EditParentForm({
  type, dogId, onSubmit, onCancel,
}: {
  type: 'dam' | 'sire',
  dogId: string,
  onSubmit: (dogId: string, parentId: number | null) => void,
  onCancel: () => void }) {
  const className = `${type}-input`;
  const [parentId, setParentId] = useState<string | undefined>(undefined);
  return (
    <div style={{
      position: 'fixed',
      width: '500px',
      height: 'auto',
      top: 'calc(50% - 150px)',
      left: 'calc(50% - 250px)',
      background: 'white',
      padding: '20px',
      borderRadius: '5px',
      boxShadow: '0 0 10px rgba(0,0,0,0.5)',
      zIndex: 1000,
    }}
    >
      <DogAutocompleteSearch
        label={type === 'dam' ? 'Emä' : 'Isä'}
        className={className}
        placeholder="Hae nimellä tai rek.nolla"
        searchOptions={{ gender: type === 'dam' ? 'Narttu' : 'Uros' }}
        setValue={(newParent) => setParentId(newParent)}
      />
      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        <Button
          onClick={() => onSubmit(dogId, parentId ? Number.parseInt(parentId, 10) : null)}
          variant="contained"
        >
          Tallenna
        </Button>
        <Button
          onClick={onCancel}
          variant="outlined"
        >
          Peruuta
        </Button>
      </div>
    </div>
  );
}

function Dashboard() {
  const [rows, setRows] = useState<Dog[]>([]);
  const [searchOpen, setSearchOpen] = useState(true);
  const { notification, notificationType, showNotification } = useNotification();
  const [availableRows, setAvailableRows] = useState<number>(0);
  const [fieldToEdit, setFieldToEdit] = useState<keyof Dog | null>(null);
  const [parentForm, setParentForm] = useState<{ type: 'damId' | 'sireId', cellId: string | number } | null>(null);
  const [deleteForm, setDeleteForm] = useState<{ dog: Dog } | null>(null);
  const gridApiRef = useGridApiRef();
  const isFirstRun = useRef(true);
  const [[page, shouldSearch], setPage] = useState<[number, boolean]>([0, false]);
  const [queryParams, setQueryParams] = useState<FullSearchControlParams>({
    gender: undefined,
    registerType: undefined,
    accepted: false,
    checkedByAdmin: undefined,
    breed: undefined,
  });

  const search = async (query: string) => {
    const { success, data, error } = await registerService.searchFullDogs(
      {
        query,
        ...queryParams,
        page: page + 1,
      },
    );
    if (success) {
      setAvailableRows(data.count);
      return data.rows;
    }
    showNotification(error, 'error');
    return [];
  };

  const {
    results, setQuery, query, loading, forceSearch,
  } = useAutocomplete({ searchFunction: search });

  useEffect(() => {
    setRows(results);
    try {
      gridApiRef?.current?.scroll({
        left: 0,
        top: 0,
      });
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, [results]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    forceSearch();
  }, [queryParams]);

  useEffect(() => {
    if (shouldSearch) {
      forceSearch();
    }
  }, [page]);

  useEffect(() => {
    setPage([0, false]);
  }, [query]);

  const handleEditRow = React.useCallback(
    async (newRow: Dog, oldRow: Dog, field: keyof Dog | null): Promise<Dog> => {
      if (field === null) return oldRow;
      setFieldToEdit(null);
      const newValue = newRow[field];
      try {
        const updatedData = { [field]: newValue };
        const dog = rows.find((d) => d.id === newRow.id);
        const key = field as keyof Dog;
        if ((dog === undefined) || dog[key] === newValue) return oldRow;
        const { data, success, error } = await registerService.updateDog(
          updatedData,
          newRow.id.toString(),
        );
        if (success) {
          showNotification('Koira päivitetty onnistuneesti');
        } else {
          showNotification(`Koiran päivittäminen epäonnistui: ${error}`);
        }
        setRows((prev) => prev.map((row) => (row.id === newRow.id ? { ...row, ...data } : row)));
        return newRow;
      } catch (error) {
        showNotification(`Koiran päivittäminen epäonnistui: ${error}`);
        // Restore the row in case of error
        setRows((prev) => [...prev]);
        return oldRow;
      }
    },
    [rows],
  );

  const editParent = async (dogId: string, parentId: number | null, type: 'damId' | 'sireId') => {
    setParentForm(null);
    const result = await registerService.updateDog({ [type]: parentId }, dogId);
    const updatedDog = result.data;
    setRows((prev) => prev.map(
      (row) => (row.id.toString() === dogId ? { ...row, ...updatedDog } : row),
    ));
    showNotification('Koira päivitetty onnistuneesti');
  };

  const deleteDog = async (dogId: string) => {
    const result = await registerService.deleteDog(dogId);
    if (result.success) {
      setRows((prev) => prev.filter((row) => row.id.toString() !== dogId));
      showNotification('Koira poistettu onnistuneesti');
    } else {
      showNotification(`Koiran poistaminen epäonnistui: ${result.error}`);
    }
    setDeleteForm(null);
  };

  return (
    <div className={styles.dataGridContainer}>
      {searchOpen && (
        <div className={styles.searchWrapper}>
          <TextInput
            placeholder="Etsi koiraa nimellä tai rek.no:lla"
            error={false}
            setValue={setQuery}
            defaultValue={query}
            white
            label="Etsi koiraa"
          />
          <SearchControls controls={queryParams} setControls={setQueryParams} isFull />
        </div>
      )}
      <ToggleButton
        value="check"
        selected={searchOpen}
        sx={{ marginBottom: '20px', marginLeft: '5px' }}
        onChange={() => {
          setSearchOpen(!searchOpen);
        }}
      >
        {searchOpen ? <ToggleOnIcon /> : <ToggleOffIcon sx={{ color: '#FFC206' }} /> }
        <Typography sx={{
          textTransform: 'none', marginLeft: '15px', color: '#433C3C', fontSize: '14px',
        }}
        >
          {searchOpen ? 'Sulje haku' : 'Avaa haku'}
        </Typography>
      </ToggleButton>
      {notification && <Notification message={notification} type={notificationType} />}
      <DataGrid
        apiRef={gridApiRef}
        paginationMode="server"
        rowCount={availableRows}
        rows={rows}
        loading={loading}
        columns={dataGridCols.columns}
        rowHeight={80}
        paginationModel={{ page, pageSize: 30 }}
        onCellEditStart={(params) => setFieldToEdit(params.field as keyof Dog)}
        onPaginationModelChange={(newModel) => setPage([newModel.page, true])}
        pageSizeOptions={[30]}
        processRowUpdate={(newRow, oldRow) => handleEditRow(newRow, oldRow, fieldToEdit)}
        onCellDoubleClick={(params) => {
          if (params.field === 'dam' && params.id) {
            setParentForm({ type: 'damId', cellId: params.id.toString() });
          } else if (params.field === 'sire' && params.id) {
            setParentForm({ type: 'sireId', cellId: params.id.toString() });
          }
        }}
        onCellClick={(params) => {
          if (params.field === 'delete' && params.row) {
            setDeleteForm({ dog: params.row });
          }
        }}
      />
      {parentForm && (
        <EditParentForm
          onCancel={() => setParentForm(null)}
          onSubmit={(dogId, parentId) => editParent(dogId, parentId, parentForm.type)}
          type={parentForm.type === 'damId' ? 'dam' : 'sire'}
          dogId={parentForm.cellId.toString()}
        />
      )}
      {deleteForm && (
        <DeleteDogForm
          dog={deleteForm.dog}
          onCancel={() => setDeleteForm(null)}
          onSubmit={deleteDog}
        />
      )}
    </div>
  );
}

export default Dashboard;
