import React from 'react';
import styles from '../../styles/chooseRegister.module.css';

function CompetitionsInfo() {
  return (
    <div className={styles.main}>
      <div className={styles.mainBlock}>
        <div className={styles.headerWrapper}>
          <h2>
            Ilmoittaudu kilpailuun ja katsele tuloksia
          </h2>
          <span style={{ marginTop: 10 }}>
            Kilpailutietokanta otetaan käyttöön vuoden 2024 aikana.
          </span>
        </div>
      </div>
    </div>
  );
}

export default CompetitionsInfo;
