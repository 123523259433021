import React from 'react';
import { AiOutlineCheck, AiOutlineExclamation } from 'react-icons/ai';
import styles from '../../styles/misc.module.css';

type Props = {
  message: string,
  type: 'error' | 'message'
};

function Notification({ message, type }: Props) {
  const success = type === 'message';
  return (
    <div className={styles.notificationWrapper}>
      { success ? (
        <AiOutlineCheck className={styles.icon} />
      ) : (
        <AiOutlineExclamation className={styles.icon} />
      )}
      <p style={{ whiteSpace: 'pre-wrap' }}>{ message }</p>
    </div>
  );
}

export default Notification;
