import React, { useMemo, useState } from 'react';
import appStyles from '../../styles/app.module.css';
import registerService from '../../services/registerService';
import {
  BaseDog, LitterInfo,
  PuppyInfo,
} from '../../types';
import viewStyles from '../../styles/viewDog.module.css';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function PuppyInformation({ puppy }: { puppy: PuppyInfo }) {
  const windowDims = useWindowDimensions();
  const isMobile = windowDims.width < 900;
  return (
    <div
      className={viewStyles.dogTableLargeRow}
      style={isMobile ? {
        background: 'white',
        marginBottom: 20,
        border: '1px solid rgb(200, 200, 200)',
      } : {
        background: 'white',
        margin: 20,
        marginTop: 0,
        marginLeft: 0,
        borderRadius: 10,
        padding: 20,
        paddingTop: 0,
        border: '1px solid rgb(200, 200, 200)',
      }}
    >
      <div
        className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}
        style={isMobile ? { borderBottom: '1px solid rgb(200, 200, 200)' } : {}}
      >
        <p
          className={viewStyles.dogInfoTh}
          style={isMobile ? { fontWeight: 'bold' } : {}}
        >
          Koiran virallinen nimi
        </p>
        <p
          className={viewStyles.dogInfoTd}
          style={isMobile ? { fontWeight: 'bold' } : {}}
        >
          {puppy.name}
        </p>
      </div>
      <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
        <p className={viewStyles.dogInfoTh}>Rekisterinumero</p>
        <p className={viewStyles.dogInfoTd}>{puppy.secondReg ?? '-'}</p>
      </div>
      <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
        <p className={viewStyles.dogInfoTh}>Mikrosirunumero</p>
        <p className={viewStyles.dogInfoTd}>{puppy.idtm}</p>
      </div>
      <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
        <p className={viewStyles.dogInfoTh}>Väri</p>
        <p className={viewStyles.dogInfoTd}>{puppy.color}</p>
      </div>
      <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
        <p className={viewStyles.dogInfoTh}>Sukupuoli</p>
        <p className={viewStyles.dogInfoTd}>{puppy.gender}</p>
      </div>
      <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
        <p className={viewStyles.dogInfoTh}>Omistajan nimi</p>
        <p className={viewStyles.dogInfoTd}>{puppy.ownerName}</p>
      </div>
      <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
        <p className={viewStyles.dogInfoTh}>Omistajan puhelinnumero</p>
        <p className={viewStyles.dogInfoTd}>{puppy.ownerPhone}</p>
      </div>
      <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
        <p className={viewStyles.dogInfoTh}>Omistajan sähköposti</p>
        <p className={viewStyles.dogInfoTd}>{puppy.ownerEmail}</p>
      </div>
      <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
        <p className={viewStyles.dogInfoTh}>Omistajan osoite</p>
        <p className={viewStyles.dogInfoTd}>{puppy.ownerAddress}</p>
      </div>
    </div>

  );
}

function ConfirmLitterData({ puppies, litter }: { puppies: PuppyInfo[], litter: LitterInfo }) {
  const [parents, setParents] = useState<{ dam: BaseDog, sire: BaseDog } | undefined>(undefined);
  const getParentInfo = async (parentId: string) => {
    const response = await registerService.getDog(parentId, ['name', 'spkyReg', 'secondReg']);
    return response.data;
  };

  const getParents = async () => {
    if (!litter.damId || !litter.sireId) return;
    const dam = await getParentInfo(litter.damId);
    const sire = await getParentInfo(litter.sireId);
    setParents({ dam, sire });
  };

  useMemo(() => getParents(), [litter.damId, litter.sireId]);

  const formatParentInfo = (parent: BaseDog | undefined) => {
    if (!parent) return '-';
    if (parent.spkyReg) return `${parent.name} (${parent.spkyReg})`;
    if (parent.secondReg) return `${parent.name} (${parent.secondReg})`;
    return parent.name;
  };

  return (
    <>
      <h1 className={appStyles.pageHeader}>Tarkista pentueen tiedot</h1>

      <div className={viewStyles.dogTableLargeRow}>
        <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
          <p className={viewStyles.dogInfoTh}>Syntymäaika</p>
          <p className={viewStyles.dogInfoTd}>{litter.birthday.toLocaleDateString('fi-FI')}</p>
        </div>
        <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
          <p className={viewStyles.dogInfoTh}>Rotu</p>
          <p className={viewStyles.dogInfoTd}>{litter.breed}</p>
        </div>
      </div>
      <div className={viewStyles.dogTableLargeRow}>
        <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
          <p className={viewStyles.dogInfoTh}>Isä</p>
          <p className={viewStyles.dogInfoTd}>{formatParentInfo(parents?.sire)}</p>
        </div>
        <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
          <p className={viewStyles.dogInfoTh}>Emä</p>
          <p className={viewStyles.dogInfoTd}>{formatParentInfo(parents?.dam)}</p>
        </div>
      </div>
      <div className={viewStyles.dogTableLargeRow}>
        <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
          <p className={viewStyles.dogInfoTh}>Kasvattajan nimi</p>
          <p className={viewStyles.dogInfoTd}>{litter.breeder}</p>
        </div>
        <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
          <p className={viewStyles.dogInfoTh}>Kasvattajan osoite</p>
          <p className={viewStyles.dogInfoTd}>{litter.breederAddress}</p>
        </div>
      </div>
      <div className={viewStyles.dogTableLargeRow}>
        <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
          <p className={viewStyles.dogInfoTh}>Kasvattajan sähköposti</p>
          <p className={viewStyles.dogInfoTd}>{litter.breederEmail}</p>
        </div>
      </div>

      <h1 className={appStyles.pageHeader}>Tarkista pentujen tiedot</h1>

      {puppies.map((puppy) => (
        <PuppyInformation puppy={puppy} key={puppy.name} />
      ))}
    </>

  );
}

export default ConfirmLitterData;
