import { useState } from 'react';
import request from '../utils/request';

export type AuthState = 'admin' | 'user' | 'unauthenticated';

const useAuth = () => {
  const [authState, setAuthState] = useState<AuthState>(() => {
    const token = localStorage.getItem('token');
    const authType = localStorage.getItem('authType');
    if (token !== null && authType !== null) {
      if (authType === 'admin' || authType === 'user') {
        request.initToken(token);
        return authType;
      }
    }
    return 'unauthenticated';
  });

  const logout = () => {
    request.setToken({ token: null, type: null });
    setAuthState('unauthenticated');
  };

  const setToken = (token: string, type: 'admin' | 'user') => {
    request.setToken({ token, type });
    setAuthState(type);
  };

  return [authState, setToken, logout] as [AuthState, (token: string) => void, () => void];
};

export default useAuth;
