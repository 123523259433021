import React from 'react';

import {
  GridColDef, GridValueGetterParams,
} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { BaseDog, User } from '../../types';
import DateInput from '../inputs/DateInput';

const REGISTER_TYPE_LIST = [
  'Perusrekisteri',
  'Jalostusrekisteri',
  'Muu rekisteri',
] as const;

const GENDER_TYPE_LIST = ['Uros', 'Narttu'] as const;
const BREED_TYPE_LIST = ['Bordercollie', 'Working kelpie', 'Muu'] as const;

const defaultValueGetter = ({ value }: { value: any }) => (value || '-');
const dateValueGetter = ({ value }: { value: any }) => (value ? new Date(value).toLocaleDateString('fi') : '-');
const getParentInfo = (parent: BaseDog | null) => {
  const reg = parent?.spkyReg ? `(${parent.spkyReg})` : '';
  return parent ? `${parent?.name} ${reg}` : '-';
};
const getCreatorInfo = (creator: Pick<User, 'firstName' | 'lastName' | 'id'> | null) => {
  if (!creator) return '-';
  let name = '';
  if (creator.firstName) name += creator.firstName;
  if (creator.lastName) name += ` ${creator.lastName}`;
  if (creator.id) name += ` (ID ${creator.id})`;
  return name;
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'accepted',
    headerName: 'Koira julkaistu',
    flex: 1,
    minWidth: 150,
    editable: true,
    type: 'boolean',
  },
  {
    field: 'paymentAccepted',
    headerName: 'Maksu hyväksytty',
    flex: 1,
    minWidth: 150,
    editable: true,
    type: 'boolean',
  },
  {
    field: 'checkedByAdmin',
    headerName: 'Tarkastettu',
    flex: 1,
    minWidth: 200,
    editable: true,
    type: 'boolean',
  },
  {
    field: 'name',
    headerName: 'Nimi',
    width: 150,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'breed',
    headerName: 'Rotu',
    width: 150,
    editable: true,
    valueGetter: defaultValueGetter,
    type: 'singleSelect',
    valueOptions: [...BREED_TYPE_LIST],
  },
  {
    field: 'spkyReg',
    headerName: 'SPKY rekisterinumero',
    width: 170,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'secondReg',
    headerName: 'Muut rek. no:t',
    width: 150,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'registerType',
    headerName: 'Rekisterityyppi',
    width: 150,
    editable: true,
    valueGetter: defaultValueGetter,
    type: 'singleSelect',
    valueOptions: [...REGISTER_TYPE_LIST],
  },
  {
    field: 'gender',
    headerName: 'Sukupuoli',
    width: 150,
    editable: true,
    valueGetter: defaultValueGetter,
    type: 'singleSelect',
    valueOptions: [...GENDER_TYPE_LIST],
  },
  {
    field: 'color',
    headerName: 'Väri',
    width: 150,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'idtm',
    headerName: 'IDTM',
    width: 150,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'birthday',
    headerName: 'Syntymäpäivä',
    width: 150,
    type: 'date',
    editable: true,
    valueFormatter: dateValueGetter,
    renderEditCell: (params) => (
      <DateInput
        error={false}
        setValue={(value) => {
          const isValidDate = value instanceof Date && !Number.isNaN(value.getTime());
          const isOverMaxDate = value && value.getTime() > new Date().getTime();
          const isOverMinDate = value && value.getTime() < new Date('1900-01-01').getTime();
          const allow = isValidDate && !isOverMaxDate && !isOverMinDate;
          if (allow) {
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value,
            });
          }
        }}
        defaultValue={params.value as string}
      />
    )
    ,
  },
  {
    field: 'dam',
    headerName: 'Emä',
    width: 250,
    editable: false,
    valueGetter: ({ value }: GridValueGetterParams) => getParentInfo(value),
  },
  {
    field: 'sire',
    headerName: 'Isä',
    width: 250,
    editable: false,
    valueGetter: ({ value }: GridValueGetterParams) => getParentInfo(value),
  },
  {
    field: 'ownerName',
    headerName: 'Omistajan nimi',
    width: 150,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'ownerEmail',
    headerName: 'Omistajan sähköposti',
    width: 200,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'ownerPhone',
    headerName: 'Omistajan puhelin',
    width: 150,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'ownerAddress',
    headerName: 'Omistajan osoite',
    width: 200,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'breeder',
    headerName: 'Kasvattaja',
    width: 150,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'breederAddress',
    headerName: 'Kasvattajan osoite',
    width: 150,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'perusrata',
    headerName: 'Perusrata',
    width: 150,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'eyes',
    headerName: 'Silmäpeilaus',
    width: 150,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'cea',
    headerName: 'CEA',
    width: 150,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'hips',
    headerName: 'Lonkat',
    width: 150,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'elbows',
    headerName: 'Kyynärät',
    width: 150,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'spine',
    headerName: 'Selkä',
    width: 150,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'shoulders',
    headerName: 'Olat',
    width: 150,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'testicles',
    headerName: 'Kivekset',
    width: 150,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'genetics',
    headerName: 'Geenit',
    width: 150,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'otherInfo',
    headerName: 'Muut tiedot (julkinen)',
    width: 250,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'otherInfoClassified',
    headerName: 'Lisätiedot (ei julkinen)',
    width: 250,
    editable: true,
    valueGetter: defaultValueGetter,
  },
  {
    field: 'createdAt',
    headerName: 'Tallennettu',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: dateValueGetter,
  },
  {
    field: 'createdBy',
    headerName: 'Tallentaja',
    width: 150,
    editable: false,
    valueGetter: ({ value }: GridValueGetterParams) => getCreatorInfo(value),
  },
  {
    field: 'updatedAt',
    headerName: 'Tiedot päivitetty',
    width: 150,
    editable: true,
    type: 'date',
    valueFormatter: dateValueGetter,
  },
  {
    field: 'accept',
    headerName: 'Rekisteriote',
    width: 150,
    sortable: false,
    renderCell: (params) => (
      <Link style={{ textDecoration: 'none' }} to={`/register/${params.id}/pdf`} rel="noopener noreferrer" target="_blank">
        <Button variant="outlined">Katso PDF</Button>
      </Link>
    ),
  },
  {
    field: 'delete',
    headerName: 'Poista',
    width: 150,
    sortable: false,
    renderCell: () => (
      <Button color="error" variant="outlined">Poista koira</Button>
    ),
  },
];

export default {
  columns,
};
