import React, { useMemo, useState } from 'react';
import appStyles from '../../styles/app.module.css';
import registerService from '../../services/registerService';
import {
  BaseDog,
  NewFamilyTreeReg,
} from '../../types';
import viewStyles from '../../styles/viewDog.module.css';

function ConfirmOtherDogData({ dog }: { dog: NewFamilyTreeReg }) {
  const [parents, setParents] = useState<{ dam: BaseDog | undefined, sire: BaseDog | undefined }>({
    dam: undefined,
    sire: undefined,
  });
  const getParentInfo = async (parentId: string) => {
    const response = await registerService.getDog(parentId, ['name', 'spkyReg', 'secondReg']);
    return response.data;
  };

  const getParents = async () => {
    const dam = dog.damId ? await getParentInfo(dog.damId) : undefined;
    const sire = dog.sireId ? await getParentInfo(dog.sireId) : undefined;
    setParents({ dam, sire });
  };

  useMemo(() => getParents(), [dog.damId, dog.sireId]);

  const formatParentInfo = (parent: BaseDog | undefined) => {
    if (!parent) return '-';
    if (parent.spkyReg) return `${parent.name} (${parent.spkyReg})`;
    if (parent.secondReg) return `${parent.name} (${parent.secondReg})`;
    return parent.name;
  };

  return (
    <>
      <h1 className={appStyles.pageHeader}>Koiran tiedot</h1>

      <div className={viewStyles.dogTableLargeRow}>
        <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
          <p className={viewStyles.dogInfoTh}>Rotu</p>
          <p className={viewStyles.dogInfoTd}>{dog.breed}</p>
        </div>
        <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
          <p className={viewStyles.dogInfoTh}>Koiran virallinen nimi</p>
          <p className={viewStyles.dogInfoTd}>{dog.name}</p>
        </div>
      </div>
      <div className={viewStyles.dogTableLargeRow}>
        <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
          <p className={viewStyles.dogInfoTh}>Rekisterinumerot</p>
          <p className={viewStyles.dogInfoTd}>{dog.secondReg ?? '-'}</p>
        </div>
        <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
          <p className={viewStyles.dogInfoTh}>Sukupuoli</p>
          <p className={viewStyles.dogInfoTd}>{dog.gender}</p>
        </div>
      </div>
      <div className={viewStyles.dogTableLargeRow}>
        <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
          <p className={viewStyles.dogInfoTh}>Syntymäaika</p>
          <p className={viewStyles.dogInfoTd}>{new Date(dog.birthday).toLocaleDateString('fi-FI')}</p>
        </div>
        <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
          <p className={viewStyles.dogInfoTh}>Väri</p>
          <p className={viewStyles.dogInfoTd}>{dog.color}</p>
        </div>
      </div>
      <div className={viewStyles.dogTableLargeRow}>
        <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
          <p className={viewStyles.dogInfoTh}>Isä</p>
          <p className={viewStyles.dogInfoTd}>{formatParentInfo(parents?.sire)}</p>
        </div>
        <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
          <p className={viewStyles.dogInfoTh}>Emä</p>
          <p className={viewStyles.dogInfoTd}>{formatParentInfo(parents?.dam)}</p>
        </div>
      </div>
    </>

  );
}

export default ConfirmOtherDogData;
