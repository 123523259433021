import React from 'react';
import styles from '../../../styles/chooseRegister.module.css';

function ChooseRegisterNavigation() {
  return (
    <div className={styles.main}>
      <div className={styles.mainBlock}>
        <div className={styles.headerWrapper}>
          <h2>
            Rekisteröi koira
          </h2>
          <span>
            Valitse aluksi rekisteröintityyppi, riippuen siitä oletko rekisteröimässä pentuetta,
            täysin uutta koiraa vai koiraa joka on jo tietokannassa.
          </span>
        </div>
      </div>
      <div className={styles.mainBlock}>
        <div className={styles.optionsWrapper}>
          <div className={styles.option}>
            <div className={styles.optionHeader}>
              <h3>1. Rekisteröi pentue</h3>
            </div>
            <p>Rekisteröi pentue, johon kuuluu useampi koira.</p>
            <button name="choose-litter" type="button" onClick={() => { window.location.href = '/litter-registration'; }}>Valitse</button>
          </div>
          <div className={styles.option}>
            <div className={styles.optionHeader}>
              <h3>2. Rekisteröi uusi koira</h3>
            </div>
            <p>Rekisteröi koira, jota ei vielä löydy tietokannasta.</p>
            <button name="choose-regular" type="button" onClick={() => { window.location.href = '/regular-registration'; }}>Valitse</button>
          </div>
          <div className={styles.option}>
            <div className={styles.optionHeader}>
              <h3>3. Rekisteröi kannassa jo oleva koira</h3>
            </div>
            <p>
              Jos koira on jo tietokannassa (esim. kilpailu- tai sukupuukoira),
              rekisteröi se tällä lomakkeella.
            </p>
            <button name="choose-completion" type="button" onClick={() => { window.location.href = '/complete-light-registration'; }}>Valitse</button>
          </div>
          <div className={styles.option}>
            <div className={styles.optionHeader}>
              <h3>4. Lisää sukupuukoira</h3>
            </div>
            <p>
              Sukupuukoira on rekisteröitävän koiran emä/isä.
              Lisää sukupuukoira ainoastaan, mikäli se ei ole jo tietokannassa.
              Sukupuukoiran lisääminen on ilmaista.
            </p>
            <button name="choose-other" type="button" onClick={() => { window.location.href = '/family-tree-registration'; }}>Valitse</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseRegisterNavigation;
