const checkIsEmpty = (
  val: string | undefined | null,
): boolean => (val !== undefined && val !== null && val.length > 0 && val.trim().length > 0);

const checkIsUndefinedOrEmpty = (val: string | undefined) => {
  if (val === undefined) return true;
  return checkIsEmpty(val);
};

const isEmail = (email: string) => String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

const checkIsEmail = (val: string | undefined | null) => {
  if (val === undefined || val === null) return false;
  const match = isEmail(val);
  return (match !== null);
};

const checkIsIn = (val: string, options: readonly string[]) => options.includes(val);

const checkIsInPast = (d: Date) => d < new Date();

const checkIsNumberOrEmpty = (value: string) => {
  if (value === undefined || value.trim() === '') {
    return true;
  }
  const isNumber = !Number.isNaN(Number(value));
  return isNumber;
};

export default {
  checkIsEmpty,
  checkIsUndefinedOrEmpty,
  checkIsIn,
  checkIsInPast,
  checkIsEmail,
  checkIsNumberOrEmpty,
};
