import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import React from 'react';
import ListItemText from '@mui/material/ListItemText';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import { Clear } from '@mui/icons-material';
import { PuppyInfo } from '../../../types';

type Props = {
  puppies: PuppyInfo[],
  onClick: (p: PuppyInfo) => void
  onRemove: (p: PuppyInfo) => void
};

function PuppyList({ puppies, onClick, onRemove }: Props) {
  return (
    <Grid item xs={12} md={6}>
      <List dense>
        {puppies.map((p) => (
          <ListItem
            key={p.name}
            secondaryAction={(
              <div style={{ display: 'flex', flexDirection: 'row', gap: '15px' }}>
                <IconButton edge="end" aria-label="delete" onClick={() => onRemove(p)}>
                  <Clear />
                </IconButton>
                <IconButton edge="end" aria-label="edit" onClick={() => onClick(p)}>
                  <EditIcon />
                </IconButton>
              </div>
          )}
          >
            <ListItemText
              primary={p.name}
              secondary={p.idtm}
            />
          </ListItem>
        ))}
      </List>
    </Grid>
  );
}

export default PuppyList;
