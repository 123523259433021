import {
  LitterInfo, NewDog, NewFamilyTreeReg, NewLightReg, PuppyInfo, ValidationResult,
} from '../types';
import constants from '../utils/constants';
import checks from './checks';

const {
  checkIsIn,
  checkIsEmpty,
  checkIsInPast,
  checkIsEmail,
  checkIsNumberOrEmpty,
} = checks;

const validateCreateDog = (data: NewDog): ValidationResult => {
  const errors = {
    breed: !checkIsIn(data.breed, constants.BREED_TYPES),
    gender: !checkIsIn(data.gender, constants.GENDER_TYPES),
    name: !checkIsEmpty(data.name),
    damId: !checkIsEmpty(data.damId),
    sireId: !checkIsEmpty(data.sireId),
    ownerName: !checkIsEmpty(data.ownerName),
    ownerEmail: !checkIsEmail(data.ownerEmail),
    birthday: !checkIsInPast(data.birthday),
    idtm: !checkIsEmpty(data.idtm),
    breeder: !checkIsEmpty(data.breeder),
    color: !checkIsEmpty(data.color),
    ownerPhone: !checkIsEmpty(data.ownerPhone),
    ownerAddress: !checkIsEmpty(data.ownerAddress),
  };
  const hasError = Object.values(errors).reduce((prevVal, val) => val || prevVal, false);
  return {
    valid: !hasError,
    errors,
  };
};

const validateLightReg = (data: NewLightReg): ValidationResult => {
  const errors = {
    name: !checkIsEmpty(data.name),
    secondReg: !checkIsEmpty(data.secondReg),
    breed: !checkIsIn(data.breed, constants.BREED_TYPES),
    gender: !checkIsIn(data.gender, constants.GENDER_TYPES),
    damId: !checkIsEmpty(data.damId),
    sireId: !checkIsEmpty(data.sireId),
    birthday: !checkIsInPast(data.birthday),
  };
  const hasError = Object.values(errors).reduce((prevVal, val) => val || prevVal, false);
  return {
    valid: !hasError,
    errors,
  };
};

const validateFamilyTreeReg = (data: NewFamilyTreeReg): ValidationResult => {
  const errors = {
    name: !checkIsEmpty(data.name),
    secondReg: !checkIsEmpty(data.secondReg),
    breed: !checkIsIn(data.breed, constants.BREED_TYPES),
    gender: !checkIsIn(data.gender, constants.GENDER_TYPES),
    damId: !checkIsNumberOrEmpty(data.damId),
    sireId: !checkIsNumberOrEmpty(data.sireId),
  };
  const hasError = Object.values(errors).reduce((prevVal, val) => val || prevVal, false);
  return {
    valid: !hasError,
    errors,
  };
};

const validatePuppy = (data: PuppyInfo): ValidationResult => {
  const errors = {
    name: !checkIsEmpty(data.name),
    gender: !checkIsIn(data.gender, constants.GENDER_TYPES),
    ownerName: !checkIsEmpty(data.ownerName),
    ownerEmail: !checkIsEmail(data.ownerEmail),
    ownerAddress: !checkIsEmpty(data.ownerAddress),
    color: !checkIsEmpty(data.color),
    idtm: !checkIsEmpty(data.idtm),
  };
  const hasError = Object.values(errors).reduce((prevVal, val) => val || prevVal, false);
  return {
    valid: !hasError,
    errors,
  };
};

const validateLitter = (data: LitterInfo): ValidationResult => {
  const errors = {
    sireId: !checkIsEmpty(data.sireId),
    damId: !checkIsEmpty(data.damId),
    breed: !checkIsIn(data.breed, constants.BREED_TYPES),
    breeder: !checkIsEmpty(data.breeder),
    breederEmail: !checkIsEmail(data.breederEmail),
    birthday: !checkIsInPast(data.birthday),
  };
  const hasError = Object.values(errors).reduce((prevVal, val) => val || prevVal, false);
  return {
    valid: !hasError,
    errors,
  };
};

const validateCompleteLightReg = (data: NewDog): ValidationResult => {
  const birthday = typeof data.birthday === 'string'
    ? new Date(data.birthday)
    : data.birthday;
  const errors = {
    breed: !checkIsIn(data.breed, constants.BREED_TYPES),
    gender: !checkIsIn(data.gender, constants.GENDER_TYPES),
    birthday: !checkIsInPast(birthday),
    ownerName: !checkIsEmpty(data.ownerName),
    ownerEmail: !checkIsEmail(data.ownerEmail),
    breeder: !checkIsEmpty(data.breeder),
    idtm: !checkIsEmpty(data.idtm),
    damId: !checkIsEmpty(data.damId),
    sireId: !checkIsEmpty(data.sireId),
  };
  const hasError = Object.values(errors).reduce((prevVal, val) => val || prevVal, false);
  return {
    valid: !hasError,
    errors,
  };
};

export default {
  validateCreateDog,
  validateLightReg,
  validateFamilyTreeReg,
  validatePuppy,
  validateLitter,
  validateCompleteLightReg,
};
