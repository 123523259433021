import axios from 'axios';
import requestUtils from '../utils/request';
import { LoginUserData, SignUpUserData } from '../validators/userValidators';

const { handleRequestError, getAuthConfig } = requestUtils;

const API_URL = '/api/auth';

const login = async (user: LoginUserData) => {
  try {
    const response = await axios.post(
      `${API_URL}/login`,
      user,
    );
    return response.data;
  } catch (e: any) {
    return handleRequestError(e);
  }
};

const signUp = async (user: SignUpUserData) => {
  try {
    const response = await axios.post(
      `${API_URL}/signup`,
      user,
    );
    return response.data;
  } catch (e: any) {
    return handleRequestError(e);
  }
};

const getAllUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}/all-users`, {
      ...getAuthConfig(),
    });
    return response.data;
  } catch (e: any) {
    return handleRequestError(e);
  }
};

const updateUser = async (user: { [key: string]: any }, id: string) => {
  try {
    const response = await axios.put(`${API_URL}/${id}`, user, {
      ...getAuthConfig(),
    });
    return response.data;
  } catch (e: any) {
    return handleRequestError(e);
  }
};

const deleteUser = async (id: string) => {
  try {
    const response = await axios.delete(`${API_URL}/${id}`, {
      ...getAuthConfig(),
    });
    return response.data;
  } catch (e: any) {
    return handleRequestError(e);
  }
};

const requestPasswordReset = async (data: { email: string }) => {
  try {
    const response = await axios.post(`${API_URL}/request-password-reset`, data);
    return response.data;
  } catch (e: any) {
    return handleRequestError(e);
  }
};

const resetPassword = async (data: { password: string, token: string }) => {
  try {
    const response = await axios.post(`${API_URL}/reset-password`, data);
    return response.data;
  } catch (e: any) {
    return handleRequestError(e);
  }
};

export default {
  login,
  signUp,
  getAllUsers,
  updateUser,
  deleteUser,
  requestPasswordReset,
  resetPassword,
};
