import React, { useEffect, useState } from 'react';
import registerService from '../../../services/registerService';
import treeStyles from '../../../styles/famTree.module.css';
import appStyles from '../../../styles/app.module.css';
import { FamilyTreeDog } from '../../../types';
import render from '../../../utils/render';

type FamilyBoardLayers = (FamilyTreeDog | undefined)[][];

function FamilyBoard({ dogId }: { dogId: string }) {
  const [layers, setLayers] = useState<FamilyBoardLayers>();

  useEffect(() => {
    const getTree = async (id: string) => {
      await registerService.getDogFamilyTree(id).then((res) => {
        setLayers(res.data);
      });
    };
    getTree(dogId);
  }, []);

  const getDisplayReg = (d: FamilyTreeDog | undefined) => {
    if (!d) return '-';
    return d.spkyReg || d.secondReg;
  };

  const getDisplayName = (d: FamilyTreeDog | undefined) => {
    if (d) {
      return <a href={`/register/${d.id}`}>{d.name}</a>;
    }
    return <p>Ei tiedossa</p>;
  };

  return (
    <div className={treeStyles.familyBoard}>
      <h1 className={appStyles.pageHeader}>Sukupuu</h1>
      <div className={treeStyles.familyTreeWrapper}>
        <p>Koiran sukupuu neljä sukupolvea taaksepäin</p>
        <p style={{ color: '#666', fontSize: 14 }}>Isä ylempänä, emä alempana</p>
        {layers && (
          <div className={treeStyles.familyTreeScrollWrapper}>
            <div className={treeStyles.familyTree}>
              <div className={treeStyles.layer}>
                {layers[0].map((d, i) => (
                  <div key={`l0-${d?.id}` ?? render.generateKey(i)}>
                    {getDisplayName(d)}
                    <p>{getDisplayReg(d)}</p>
                  </div>
                ))}
              </div>
              <div className={treeStyles.layer}>
                {layers[1].map((d, i) => (
                  <div key={`l1-${d?.id}` ?? render.generateKey(i)}>
                    {getDisplayName(d)}
                    <p>{getDisplayReg(d)}</p>
                  </div>
                ))}
              </div>
              <div className={treeStyles.layer}>
                {layers[2].map((d, i) => (
                  <div key={`l2-${d?.id}` ?? render.generateKey(i)}>
                    {getDisplayName(d)}
                    <p>{getDisplayReg(d)}</p>
                  </div>
                ))}
              </div>
              <div className={treeStyles.layer}>
                {layers[3].map((d, i) => (
                  <div key={`l3-${d?.id}` ?? render.generateKey(i)}>
                    {getDisplayName(d)}
                    <p>{getDisplayReg(d)}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FamilyBoard;
