import React, { useEffect } from 'react';
import styles from '../../styles/admin.module.css';
import Notification from '../misc/Notification';
import TextInput from '../inputs/TextInput';
import useForm from '../../hooks/useForm';
import useNotification from '../../hooks/useNotification';
import SaveButton from '../misc/SaveButton';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import userValidators from '../../validators/userValidators';
import authService from '../../services/authService';

function SignUp() {
  const { notification, showNotification, notificationType } = useNotification();
  const responseHandler = (res: any) => {
    try {
      const { success, error } = res;
      if (success) {
        showNotification('Käyttäjä luotu. Saat ilmoituksen sähköpostilla, kun käyttäjäsi on hyväksytty.', 'message');
        setTimeout(() => {
          window.location.href = '/';
        }, 5000);
      } else {
        showNotification(error ?? 'Jokin meni pieleen. Tarkista, että kaikki kentät on täytetty.', 'error');
      }
    } catch (e) {
      showNotification('Jokin meni pieleen', 'error');
    }
  };

  const {
    updateField, submit, errors, isLoading, values,
  } = useForm({
    validator: userValidators.validateSignUpUser,
    submitter: authService.signUp,
    responseHandler,
  });

  useEffect(() => {
    const hasError = Object.values(errors).reduce((p, n) => p || n, false);
    if (hasError) showNotification('Tarkista puuttuvat kentät', 'error');
  }, [errors]);

  const windowDims = useWindowDimensions();
  const isMobile = windowDims.width < 600;

  return (
    <div className={styles.formWrapper}>
      {notification && <Notification message={notification} type={notificationType} /> }
      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
      >
        <h3>Luo käyttäjätunnus</h3>
        <p>
          Saat sähköposti-ilmoituksen, kun käyttäjätilisi on hyväksytty.
        </p>
        <TextInput
          label="Sähköposti"
          placeholder="Sähköposti"
          setValue={updateField('email')}
          defaultValue={values.email}
          error={errors.email}
          white={isMobile}
          type="email"
          autoComplete="username"
          className="email-input"
          required
        />
        <TextInput
          label="Etunimi"
          placeholder="Etunimi"
          setValue={updateField('firstName')}
          defaultValue={values.firstName}
          error={errors.firstName}
          white={isMobile}
          className="name-input"
          required
        />
        <TextInput
          label="Sukunimi"
          placeholder="Sukunimi"
          setValue={updateField('lastName')}
          defaultValue={values.lastName}
          error={errors.lastName}
          white={isMobile}
          className="name-input"
          required
        />
        <TextInput
          label="Puhelin"
          placeholder="Puhelin"
          setValue={updateField('phone')}
          defaultValue={values.phone}
          error={errors.phone}
          white={isMobile}
          className="phone-input"
          type="tel"
          required
        />
        <TextInput
          label="Salasana"
          placeholder="Salasana"
          setValue={updateField('password')}
          defaultValue={values.password}
          error={errors.password}
          white={isMobile}
          type="password"
          autoComplete="new-password"
          className="password-input"
          required
        />
        <SaveButton
          text="Luo käyttäjä"
          size="medium"
          variant="contained"
          onClick={submit}
          isLoading={isLoading}
          type="submit"
        />
        <p>
          Onko sinulla jo käyttäjätunnus?
          <br />
          <a href="/login">Kirjaudu sisään</a>
        </p>
      </form>
    </div>
  );
}

export default SignUp;
