import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import styles from '../../styles/misc.module.css';
import { AuthState } from '../../hooks/useAuth';

const pages = [
  {
    page: 'Rekisteri',
    link: '/',
  },
  {
    page: 'Rekisteröi koira',
    link: '/register',
  },
  {
    page: 'Kilpailut',
    link: '/competitions',
  },
];

const externalPages = [
  {
    page: 'Ohjeet',
    link: 'https://www.spky.fi/wopr/rekisterointi',
  },
  {
    page: 'Muutospyynnöt',
    link: 'https://forms.gle/PPUxWmETTnJTeeNJA',
  },
];

type Props = { logout: () => void, authState: AuthState };

function Navbar({ logout, authState }: Props) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElAuth, setAnchorElAuth] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenAuthMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAuth(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setAnchorElAuth(null);
  };

  return (
    <AppBar position="sticky" sx={{ top: 0 }} color="secondary" className={styles.nav}>
      <Container sx={{ maxWidth: '1200px', position: 'relative' }}>
        <Toolbar disableGutters>
          <Toolbar>
            <Link to="/">
              <Box
                component="img"
                sx={{
                  height: 50,
                  display: { xs: 'none', md: 'flex' },
                }}
                alt="SPKY"
                src="/spky-uusi-logo-transparent.png"
              />
            </Link>
          </Toolbar>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="Etusivu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              { pages.map(({ page, link }) => (
                <Link key={page} to={link} className={styles.navLink}>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                </Link>
              ))}
              {externalPages.map(({ page, link }) => (
                <a key={page} href={link} className={styles.navLink} rel="noopener noreferrer" target="_blank">
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                </a>
              ))}

              {authState === 'admin' && (
                <Container>
                  <Link to="/admin" className={styles.navLink}>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">Hallinnoi koirarekisteriä</Typography>
                    </MenuItem>
                  </Link>
                  <Link to="/admin/users" className={styles.navLink}>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">Hallinnoi käyttäjiä</Typography>
                    </MenuItem>
                  </Link>
                  <MenuItem onClick={() => { handleCloseNavMenu(); logout(); }}>
                    <Typography textAlign="center">Kirjaudu ulos</Typography>
                  </MenuItem>
                </Container>
              )}
              { authState === 'user' && (
              <MenuItem onClick={() => { handleCloseNavMenu(); logout(); }}>
                <Typography textAlign="center">Kirjaudu ulos</Typography>
              </MenuItem>
              )}
              {authState === 'unauthenticated' && (
                <Container>
                  <Link to="/login" className={styles.navLink}>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">Kirjaudu sisään</Typography>
                    </MenuItem>
                  </Link>
                  <Link to="/signup" className={styles.navLink}>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">Luo käyttäjä</Typography>
                    </MenuItem>
                  </Link>
                </Container>
              )}
            </Menu>
          </Box>
          <Toolbar sx={{ display: { xs: 'flex', md: 'none' } }}>
            <Box
              component="img"
              sx={{
                height: 50,
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' },
              }}
              alt="SPKY"
              src="/spky-uusi-logo-transparent.png"
            />
          </Toolbar>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map(({ page, link }) => (
              <Link key={page} to={link} className={styles.navLink}>
                <Button
                  onClick={handleCloseNavMenu}
                  className={styles.navLink}
                  sx={{
                    ':hover': { backgroundColor: 'lightgray' }, fontSize: 12, my: 2, display: 'block',
                  }}
                >
                  {page}
                </Button>
              </Link>
            ))}
            {externalPages.map(({ page, link }) => (
              <a key={page} href={link} className={styles.navLink} rel="noopener noreferrer" target="_blank">
                <Button
                  onClick={handleCloseNavMenu}
                  className={styles.navLink}
                  sx={{
                    ':hover': { backgroundColor: 'lightgray' }, fontSize: 12, my: 2, display: 'block',
                  }}
                >
                  {page}
                </Button>
              </a>
            ))}
          </Box>
        </Toolbar>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className={styles.navButton}>
          <IconButton
            size="large"
            aria-label="Menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenAuthMenu}
            color="inherit"
          >
            <MenuIcon fontSize="large" />
          </IconButton>
          <Menu
            id="menu-auth"
            anchorEl={anchorElAuth}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElAuth)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          >
            { authState === 'admin' && (
              <Container>
                <Link to="/admin" className={styles.navLink}>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Hallinnoi koirarekisteriä</Typography>
                  </MenuItem>
                </Link>
                <Link to="/admin/users" className={styles.navLink}>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Hallinnoi käyttäjiä</Typography>
                  </MenuItem>
                </Link>
                <MenuItem onClick={() => { handleCloseNavMenu(); logout(); }}>
                  <Typography textAlign="center">Kirjaudu ulos</Typography>
                </MenuItem>
              </Container>
            )}
            { authState === 'user' && (
              <MenuItem onClick={() => { handleCloseNavMenu(); logout(); }}>
                <Typography textAlign="center">Kirjaudu ulos</Typography>
              </MenuItem>
            )}
            { authState === 'unauthenticated' && (
              <Container>
                <Link to="/login" className={styles.navLink}>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Kirjaudu sisään</Typography>
                  </MenuItem>
                </Link>
                <Link to="/signup" className={styles.navLink}>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Luo käyttäjä</Typography>
                  </MenuItem>
                </Link>
              </Container>
            )}
          </Menu>
        </Box>
      </Container>
    </AppBar>
  );
}
export default Navbar;
