import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import styles from '../../../styles/dogForm.module.css';
import registerService from '../../../services/registerService';
import viewStyles from '../../../styles/viewDog.module.css';
import appStyles from '../../../styles/app.module.css';
import { PublicDogWithParents } from '../../../types';
import FamilyTree from '../misc/FamilyTree';
import FamilyBoard from '../misc/FamilyBoard';
import useNotification from '../../../hooks/useNotification';
import Notification from '../../misc/Notification';

function ViewDog({ isAuthenticated }: { isAuthenticated: boolean }) {
  const [dog, setDog] = useState<PublicDogWithParents | null>(null);
  const { dogId }: { dogId?: string } = useParams();
  const { notification, notificationType, showNotification } = useNotification();

  useEffect(() => {
    const getDog = async (id: string) => {
      const { data, success, error } = await registerService.getDog(id);
      if (!success) {
        showNotification(error, 'error');
      } else {
        setDog(data);
      }
    };
    if (dogId) getDog(dogId);
  }, [dogId]);

  return (
    <div className={viewStyles.wrapper}>
      <div className={styles.formWrapper}>
        {notification && (
        <Notification message={notification} type={notificationType} />
        )}
        {dog && (
        <div style={{
          width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center',
        }}
        >
          <div className={viewStyles.infoTable}>
            <h1 className={appStyles.pageHeader}>Koiran tiedot</h1>

            <div className={viewStyles.dogTableLargeRow}>
              <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
                <p className={viewStyles.dogInfoTh}>SPKY rekisterinumero</p>
                <p className={viewStyles.dogInfoTd}>{dog.spkyReg ? dog.spkyReg : '-'}</p>
              </div>
              <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
                <p className={viewStyles.dogInfoTh}>Nimi</p>
                <p className={viewStyles.dogInfoTd}>{dog.name}</p>
              </div>
            </div>
            <div className={viewStyles.dogTableLargeRow}>
              <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
                <p className={viewStyles.dogInfoTh}>Muut rekisterinumerot</p>
                <p className={viewStyles.dogInfoTd}>
                  {dog.secondReg ? dog.secondReg : '-'}
                </p>
              </div>
              <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
                <p className={viewStyles.dogInfoTh}>Syntymäaika</p>
                <p className={viewStyles.dogInfoTd}>{dog?.birthday ? new Date(dog.birthday).toLocaleDateString('fi') : '-'}</p>
              </div>
            </div>
            <div className={viewStyles.dogTableLargeRow}>
              <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
                <p className={viewStyles.dogInfoTh}>Rekisterityyppi</p>
                <p className={viewStyles.dogInfoTd}>{dog.registerType}</p>
              </div>
              <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
                <p className={viewStyles.dogInfoTh}>Sukupuoli</p>
                <p className={viewStyles.dogInfoTd}>{dog.gender}</p>
              </div>
            </div>
            <div className={viewStyles.dogTableLargeRow}>
              <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
                <p className={viewStyles.dogInfoTh}>Rotu</p>
                <p className={viewStyles.dogInfoTd}>{dog.breed}</p>
              </div>
              <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
                <p className={viewStyles.dogInfoTh}>Väri</p>
                <p className={viewStyles.dogInfoTd}>{dog.color}</p>
              </div>
            </div>
            <div className={viewStyles.dogTableLargeRow}>
              <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
                <p className={viewStyles.dogInfoTh}>IDTM</p>
                <p className={viewStyles.dogInfoTd}>{dog.idtm ? dog.idtm : '-'}</p>
              </div>
              <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
                <p className={viewStyles.dogInfoTh}>Muut tiedot</p>
                <p className={viewStyles.dogInfoTd}>
                  {dog.otherInfo ? dog.otherInfo : '-'}
                </p>
              </div>
            </div>
            <div>
              <div className={viewStyles.dogTableLargeRow}>
                <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
                  <p className={viewStyles.dogInfoTh}>Perusrata</p>
                  <p className={viewStyles.dogInfoTd}>
                    {dog.perusrata ? dog.perusrata : '-'}
                  </p>
                </div>
                <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
                  <p className={viewStyles.dogInfoTh}>Silmäpeilaus</p>
                  <p className={viewStyles.dogInfoTd}>{dog.eyes ? dog.eyes : '-'}</p>
                </div>
              </div>
              <div className={viewStyles.dogTableLargeRow}>
                <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
                  <p className={viewStyles.dogInfoTh}>CEA-tulos</p>
                  <p className={viewStyles.dogInfoTd}>{dog.cea ? dog.cea : '-'}</p>
                </div>
                <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
                  <p className={viewStyles.dogInfoTh}>Lonkat</p>
                  <p className={viewStyles.dogInfoTd}>{dog.hips ? dog.hips : '-'}</p>
                </div>
              </div>
              <div className={viewStyles.dogTableLargeRow}>
                <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
                  <p className={viewStyles.dogInfoTh}>Kyynärät</p>
                  <p className={viewStyles.dogInfoTd}>{dog.elbows ? dog.elbows : '-'}</p>
                </div>
                <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
                  <p className={viewStyles.dogInfoTh}>Selkä</p>
                  <p className={viewStyles.dogInfoTd}>{dog.spine ? dog.spine : '-'}</p>
                </div>
              </div>
              <div className={viewStyles.dogTableLargeRow}>
                <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
                  <p className={viewStyles.dogInfoTh}>Olat</p>
                  <p className={viewStyles.dogInfoTd}>
                    {dog.shoulders ? dog.shoulders : '-'}
                  </p>
                </div>
                <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
                  <p className={viewStyles.dogInfoTh}>Kivekset</p>
                  <p className={viewStyles.dogInfoTd}>
                    {dog.testicles ? dog.testicles : '-'}
                  </p>
                </div>
              </div>
              <div className={viewStyles.dogTableLargeRow}>
                <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
                  <p className={viewStyles.dogInfoTh}>Geenitestit</p>
                  <p className={viewStyles.dogInfoTd}>
                    {dog.genetics ? dog.genetics : '-'}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {dog && (
            <>
              <FamilyTree dog={dog} />
              <FamilyBoard key={dog.id} dogId={dog.id.toString()} />
            </>
          )}
          { isAuthenticated && (
            <div style={{ width: '100%' }}>
              <Link to={`/register/${dogId}/pdf`} style={{ textDecoration: 'none' }} rel="noopener noreferrer" target="_blank">
                <Button sx={{ margin: 0, marginTop: '40px' }} variant="contained">Katso PDF</Button>
              </Link>
            </div>
          ) }
        </div>
        )}
      </div>
    </div>
  );
}

export default ViewDog;
