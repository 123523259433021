import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { fiFI } from '@mui/x-data-grid';
import Dashboard from './components/admin/Dashboard';
import PaymentRedirect from './components/payments/PaymentRedirect';
import LightRegistration from './components/register/forms/LightRegistrationForm';
import FamilyTreeRegistration from './components/register/forms/FamilyTreeRegistration';
import RegisterDog from './components/register/forms/RegisterDog';
import Navbar from './components/misc/Navbar';
import LitterRegistration from './components/register/forms/LitterRegistration';
import List from './components/register/listing/List';
import ViewDog from './components/register/listing/ViewDog';
import CompleteLightRegistration from './components/register/forms/CompleteLightRegistration';
import Login from './components/admin/Login';
import routes from './utils/routes';
import useAuth from './hooks/useAuth';
import DogPdf from './components/register/misc/DogPdf';
import GDPR from './components/misc/GDPR';
import PaymentTerms from './components/misc/PaymentTerms';
import Footer from './components/misc/Footer';
import SignUp from './components/admin/SignUp';
import UserDashboard from './components/admin/UserDashboard';
import RequestPasswordReset from './components/admin/RequestPasswordReset';
import ResetPassword from './components/admin/ResetPassword';
import ChooseRegisterNavigation from './components/register/forms/ChooseRegisterNavigation';
import CompetitionsInfo from './components/misc/CompetitionsInfo';
import DogCreated from './components/register/misc/DogCreated';

const { PrivateRoute, LoginRoute } = routes;

const theme = createTheme(
  {
    palette: {
      secondary: {
        main: '#fefefe',
      },
      primary: {
        main: '#433C3C',
      },
    },
  },
  fiFI,
);

function App() {
  const [authState, setToken, logout] = useAuth();
  const location = useLocation();
  const showFooter = location.pathname !== '/admin';

  return (
    <ThemeProvider theme={theme}>
      <Navbar logout={logout} authState={authState} />
      <div style={{
        minHeight: 'calc(100vh - 295px)',
        paddingBottom: '2rem',
      }}
      >
        <Routes>
          <Route path="/validate-payment" element={<PaymentRedirect />} />
          <Route path="/competitions" element={<CompetitionsInfo />} />
          <Route
            path="/register"
            element={(
              <PrivateRoute isAuthenticated={authState !== 'unauthenticated'} redirectTo="/login">
                <ChooseRegisterNavigation />
              </PrivateRoute>
            )}
          />
          <Route
            path="/regular-registration"
            element={(
              <PrivateRoute isAuthenticated={authState !== 'unauthenticated'} redirectTo="/login">
                <RegisterDog />
              </PrivateRoute>
            )}
          />
          <Route path="/created-dog/:dogId" element={<DogCreated dogId={location.pathname.split('/')[2]} />} />
          <Route path="/register/:dogId" element={<ViewDog isAuthenticated={authState === 'admin'} />} />
          <Route
            path="/register/:dogId/pdf"
            element={(
              <PrivateRoute isAuthenticated={authState === 'admin'} redirectTo="/login">
                <DogPdf />
              </PrivateRoute>
          )}
          />
          <Route
            path="/light-registration"
            element={(
              <PrivateRoute isAuthenticated={authState !== 'unauthenticated'} redirectTo="/login">
                <LightRegistration />
              </PrivateRoute>
      )}
          />
          <Route
            path="/complete-light-registration"
            element={(
              <PrivateRoute isAuthenticated={authState !== 'unauthenticated'} redirectTo="/login">
                <CompleteLightRegistration />
              </PrivateRoute>
        )}
          />
          <Route
            path="/family-tree-registration"
            element={(
              <PrivateRoute isAuthenticated={authState !== 'unauthenticated'} redirectTo="/login">
                <FamilyTreeRegistration />
              </PrivateRoute>
        )}
          />
          <Route
            path="/litter-registration"
            element={(
              <PrivateRoute isAuthenticated={authState !== 'unauthenticated'} redirectTo="/login">
                <LitterRegistration />
              </PrivateRoute>
        )}
          />
          <Route path="/terms" element={<GDPR />} />
          <Route path="/payment-terms" element={<PaymentTerms />} />
          <Route
            path="/login"
            element={(
              <LoginRoute isAuthenticated={authState !== 'unauthenticated'}>
                <Login authenticate={setToken} />
              </LoginRoute>
        )}
          />
          <Route
            path="/signup"
            element={(
              <LoginRoute isAuthenticated={authState !== 'unauthenticated'}>
                <SignUp />
              </LoginRoute>
        )}
          />
          <Route
            path="/admin"
            element={(
              <PrivateRoute isAuthenticated={authState === 'admin'} redirectTo={authState === 'user' ? '/' : '/login'}>
                <Dashboard />
              </PrivateRoute>
          )}
          />
          <Route
            path="/admin/users"
            element={(
              <PrivateRoute isAuthenticated={authState === 'admin'} redirectTo={authState === 'user' ? '/' : '/login'}>
                <UserDashboard />
              </PrivateRoute>
          )}
          />
          <Route path="/request-password-reset" element={<RequestPasswordReset />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/" element={<List />} />
        </Routes>
      </div>
      {showFooter && <Footer /> }
    </ThemeProvider>
  );
}

export default App;
