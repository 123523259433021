import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import React from 'react';
import styles from '../../styles/dogForm.module.css';

type Props = {
  label: string,
  setValue: (arg: string) => void,
  options: [string, string][],
  error: boolean,
  className?: string,
  defaultValue?: string | undefined
  info?: string,
};

function SelectInput({
  label, setValue, options, error, className, defaultValue, info,
}: Props) {
  let blockClass = styles.formInputBlock;
  if (className) blockClass += ` ${className}`;

  return (
    <div className={blockClass}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          error={error}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={defaultValue || ''}
          label={label}
          onChange={(ev) => setValue(ev.target.value)}
        >
          { options.map((option) => (
            <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
          )) }
        </Select>
        {info && (
          <FormHelperText>{info}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
}

SelectInput.defaultProps = {
  className: undefined,
  defaultValue: undefined,
  info: undefined,
};

export default SelectInput;
