import React, { useEffect, useState } from 'react';
import formStyles from '../../../styles/dogForm.module.css';
import appStyles from '../../../styles/app.module.css';
import TextInput from '../../inputs/TextInput';
import useForm from '../../../hooks/useForm';
import { Breed, LitterInfo, PuppyInfo } from '../../../types';
import SelectInput from '../../inputs/SelectInput';
import constants from '../../../utils/constants';
import DateInput from '../../inputs/DateInput';
import SaveButton from '../../misc/SaveButton';
import dogValidators from '../../../validators/dogValidators';
import Notification from '../../misc/Notification';
import PuppyList from '../misc/PuppyList';
import useNotification from '../../../hooks/useNotification';
import DogAutocompleteSearch from '../../inputs/DogAutocompleteSearch';
import ConfirmPurchase from '../../payments/ConfirmPurchase';

function LitterRegistration() {
  const [puppies, setPuppies] = useState<PuppyInfo[]>([]);
  const { notification, showNotification } = useNotification();
  const urlParams = new URLSearchParams(window.location.search);
  const [submitted, setSubmitted] = useState<boolean>(urlParams.get('submitted') === 'true');

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const submittedParam = searchParams.get('submitted');
    const isSubmitted = submittedParam === 'true';
    setSubmitted(isSubmitted);
  }, [urlParams]);

  const setSubmittedWithUrl = (value: boolean) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('submitted', value.toString());
    setSubmitted(value);
    if (value) {
      window.history.pushState({}, '', `${window.location.pathname}?${searchParams}`);
    } else {
      window.history.replaceState({}, '', window.location.pathname);
    }
  };

  const addPuppy = (puppy: PuppyInfo) => {
    setPuppies((p) => [...p, puppy]);
  };

  const litterForm = useForm({
    validator: dogValidators.validateLitter,
    submitter: async () => {
      if (puppies.length === 0) {
        showNotification('Lisää vähintään yksi pentu');
        return;
      }
      // Using a slight delay to indicate state change
      // to the user before the next step
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 1500));
      setSubmittedWithUrl(true);
    },
  });

  const puppyForm = useForm({
    submitter: addPuppy,
    validator: dogValidators.validatePuppy,
  });

  useEffect(() => {
    const hasError = Object.values(litterForm.errors).reduce((p, n) => p || n, false);
    if (hasError) showNotification('Tarkista pentueen kentät');
  }, [litterForm.errors]);

  useEffect(() => {
    const hasError = Object.values(puppyForm.errors).reduce((p, n) => p || n, false);
    if (hasError) showNotification('Tarkista pennun kentät');
  }, [puppyForm.errors]);

  const updateLitterField = (fieldKey: keyof LitterInfo) => litterForm.updateField(fieldKey);
  const updatePuppyField = (fieldKey: keyof PuppyInfo) => puppyForm.updateField(fieldKey);

  const clearInputs = () => {
    puppyForm.initialize({});
  };

  const editPuppy = (puppy: PuppyInfo) => {
    setPuppies((old) => old.filter((p) => p.name !== puppy.name));
    puppyForm.initialize(puppy);
  };

  const removePuppy = (puppy: PuppyInfo) => {
    setPuppies((old) => old.filter((p) => p.name !== puppy.name));
  };

  const submitPuppy = async () => {
    const success = await puppyForm.submit();
    if (success) clearInputs();
  };

  if (submitted) {
    return (
      <ConfirmPurchase
        registrationData={{
          type: 'PUPPY_REG',
          data: { puppies, litter: (litterForm.values as LitterInfo) },
        }}
        onClose={() => setSubmittedWithUrl(false)}
      />
    );
  }

  // Used to restrict parent search to only Bordercollie and Kelpie,
  // if the registered dog is one of them
  const parentSearchBreed: Breed | undefined = ['Bordercollie', 'Working kelpie'].includes(litterForm.values.breed)
    ? litterForm.values.breed : undefined;

  return (
    <div className={formStyles.formWrapper}>
      {notification && <Notification message={notification} type="error" /> }
      <form className={formStyles.form}>
        <div className={formStyles.formGroup}>
          <h2 className={appStyles.pageHeader}>Rekisteröi pentue</h2>
          <SelectInput
            label="Rotu *"
            setValue={updateLitterField('breed')}
            options={constants.BREED_TYPES.map((b) => [b, b])}
            defaultValue={litterForm.values.breed}
            error={litterForm.errors.breed}
            className="breed-input"
          />
          <DogAutocompleteSearch
            label="Isä *"
            placeholder="Hae nimellä tai rek.nolla"
            searchOptions={{ gender: 'Uros', breed: parentSearchBreed }}
            defaultValue={litterForm.values.sireId}
            setValue={updateLitterField('sireId')}
            error={litterForm.errors.sireId}
            className="sire-input"
          />
          <DogAutocompleteSearch
            label="Emä *"
            placeholder="Hae nimellä tai rek.nolla"
            searchOptions={{ gender: 'Narttu', breed: parentSearchBreed }}
            defaultValue={litterForm.values.damId}
            setValue={updateLitterField('damId')}
            error={litterForm.errors.damId}
            className="dam-input"
          />
          <DateInput
            setValue={updateLitterField('birthday')}
            label="Syntymäaika *"
            defaultValue={litterForm.values.birthday}
            error={litterForm.errors.birthday}
            className="birthday-input"
          />
          <h2 className={appStyles.pageHeader}>Kasvattajan tiedot</h2>
          <TextInput
            required
            label="Kasvattajan nimi"
            setValue={updateLitterField('breeder')}
            error={litterForm.errors.breeder}
            defaultValue={litterForm.values.breeder}
            className="breeder-name-input"
          />
          <TextInput
            required
            label="Kasvattajan sähköposti"
            setValue={updateLitterField('breederEmail')}
            error={litterForm.errors.breederEmail}
            defaultValue={litterForm.values.breederEmail}
            className="breeder-email-input"
          />
          <TextInput
            label="Kasvattajan paikkakunta tai maa"
            setValue={updateLitterField('breederAddress')}
            error={litterForm.errors.breederAddress}
            defaultValue={litterForm.values.breederAddress}
            className="breeder-address-input"
          />
        </div>
        <div className={formStyles.formGroup}>
          <h2 className={appStyles.pageHeader}>Lisää pennut</h2>
          <TextInput
            required
            label="Omistajan nimi"
            placeholder="Etunimi Sukunimi"
            setValue={updatePuppyField('ownerName')}
            error={puppyForm.errors.ownerName}
            defaultValue={puppyForm.values.ownerName}
            className="owner-name-input"
          />
          <TextInput
            label="Omistajan puhelinnumero"
            setValue={updatePuppyField('ownerPhone')}
            error={puppyForm.errors.ownerPhone}
            defaultValue={puppyForm.values.ownerPhone}
            className="owner-phone-input"
          />
          <TextInput
            required
            label="Omistajan sähköpostiosoite"
            setValue={updatePuppyField('ownerEmail')}
            error={puppyForm.errors.ownerEmail}
            defaultValue={puppyForm.values.ownerEmail}
            className="owner-email-input"
          />
          <TextInput
            required
            label="Omistajan osoite"
            setValue={updatePuppyField('ownerAddress')}
            error={puppyForm.errors.ownerAddress}
            defaultValue={puppyForm.values.ownerAddress}
            className="owner-address-input"
          />
          <TextInput
            required
            label="Nimi"
            setValue={updatePuppyField('name')}
            error={puppyForm.errors.name}
            defaultValue={puppyForm.values.name}
            className="puppy-name-input"
            id="puppy-name-input"
          />
          <TextInput
            required
            label="Väri"
            setValue={updatePuppyField('color')}
            error={puppyForm.errors.color}
            defaultValue={puppyForm.values.color}
            className="puppy-color-input"
          />
          <TextInput
            label="Muut rekisterinumerot (esim. SKL numero)"
            setValue={updatePuppyField('secondReg')}
            error={puppyForm.errors.secondReg}
            defaultValue={puppyForm.values.secondReg}
            className="puppy-second-reg-input"
          />
          <TextInput
            required
            label="Mikrosirunumero"
            setValue={updatePuppyField('idtm')}
            error={puppyForm.errors.idtm}
            defaultValue={puppyForm.values.idtm}
            className="puppy-idtm-input"
          />
          <SelectInput
            label="Sukupuoli *"
            setValue={updatePuppyField('gender')}
            options={constants.GENDER_TYPES.map((b) => [b, b])}
            defaultValue={puppyForm.values.gender}
            error={puppyForm.errors.gender}
            className="puppy-gender-input"
          />
          <SaveButton
            text="Lisää pentu"
            size="medium"
            variant="outlined"
            className="add-puppy"
            onClick={submitPuppy}
          />
          <PuppyList onRemove={removePuppy} onClick={editPuppy} puppies={puppies} />
          <br />
          <SaveButton
            style={{
              width: '100%',
            }}
            text="Jatka tietojen tarkistukseen"
            size="large"
            variant="contained"
            className="submit"
            onClick={litterForm.submit}
            isLoading={litterForm.isLoading}
          />
        </div>
      </form>
    </div>
  );
}

export default LitterRegistration;
