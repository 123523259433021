import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../styles/footer.module.css';

function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.footerColumn}>
        <p className={styles.footerText}>Yhteydenotot</p>
        <p className={styles.footerText}>spkytietokanta@gmail.com</p>
      </div>
      <div className={styles.footerColumn}>
        <Link to="/" className={styles.footerLink}>Rekisteri</Link>
        <Link to="/register" className={styles.footerLink}>Rekisteröi koira</Link>
      </div>
      <div className={styles.footerColumn}>
        <Link to="/terms" className={styles.footerLink}>Tietosuojaseloste</Link>
      </div>

    </div>
  );
}

export default Footer;
