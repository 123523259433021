import axios, { AxiosError } from 'axios';

let authToken: string | null = null;

const handleRequestError = (e: unknown) => {
  const error = e as Error | AxiosError;
  if (axios.isAxiosError(error)) {
    return error.response?.data;
  }
  return { success: false };
};

const setToken = ({ token, type }: { token: string, type: 'admin' | 'user' } | { token: null, type: null }) => {
  if (token && type) {
    authToken = `Bearer ${token}`;
    localStorage.setItem('token', authToken);
    localStorage.setItem('authType', type);
  } else {
    authToken = null;
    localStorage.removeItem('token');
    localStorage.removeItem('authType');
  }
};

const initToken = (token: string) => {
  authToken = token;
};

const getAuthConfig = () => ({
  headers: {
    Authorization: authToken ?? '',
  },
});

export default {
  handleRequestError,
  setToken,
  initToken,
  getAuthConfig,
  authToken,
};
