import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import useAutocomplete from '../../hooks/useAutocomplete';
import registerService from '../../services/registerService';
import formStyles from '../../styles/dogForm.module.css';
import styles from '../../styles/parentSearch.module.css';
import searchStyles from '../../styles/search.module.css';
import { SearchOptions } from '../../types';

type Props = {
  setValue: (arg: string | undefined) => void,
  placeholder: string,
  label: string,
  error?: boolean,
  searchOptions: SearchOptions,
  className?: string,
  defaultValue?: string | undefined,
};

function DogAutocompleteSearch({
  setValue, placeholder, error, searchOptions, label, className, defaultValue,
}: Props) {
  const searchDogs = async (query: string) => {
    const { data } = await registerService.searchDogs({
      ...searchOptions,
      query,
    });
    return data.rows;
  };

  const [resultsOpen, setResultsOpen] = useState(false);
  const [initDone, setInitDone] = useState(false);
  const {
    results, onChange, query, setQuery,
  } = useAutocomplete({ searchFunction: searchDogs, searchOptions });

  const select = (option: any) => {
    setValue(option.id.toString());
    setQuery(option.name);
    setResultsOpen(false);
  };

  useEffect(() => {
    const fetchDog = async (id: string) => {
      setInitDone(true);
      const { data } = await registerService.getDog(id);
      const option = { name: data.name, id };
      select(option);
    };
    if (defaultValue && !initDone) fetchDog(defaultValue);
  }, [defaultValue]);
  const resultsClass = resultsOpen
    ? `${styles.inputResultsWrapper}`
    : `${styles.inputResultsWrapper} ${styles.closedResults}`;

  let blockClass = `${formStyles.formInputBlock} ${styles.absoluteInputWrapper}`;
  if (className) blockClass += ` ${className}`;

  /**
   * If the user clicks backspace, clear the value of the input field
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setValue(undefined);
    }
    onChange(e);
  };

  return (
    <div className={blockClass}>
      <TextField
        placeholder={placeholder}
        autoComplete="off"
        autoCorrect="off"
        error={error}
        sx={{
          backgroundColor: 'white',
        }}
        id="filled-basic"
        value={query}
        label={label}
        onFocus={() => setResultsOpen(true)}
        onBlur={() => setResultsOpen(false)}
        variant="filled"
        onChange={handleChange}
        className="autocomplete-search"
      />
      <div className={resultsClass}>
        {results.map((d) => (
          <div
            tabIndex={0}
            onMouseDown={() => select(d)}
            onKeyDown={() => select(d)}
            key={d.id}
            className={`${styles.parentSearchResult} autocomplete-results`}
            role="button"
          >
            <p className={`${searchStyles.tableData} ${styles.parentSearchResultData}`}>
              {d.spkyReg ? d.spkyReg : d.secondReg}
            </p>
            <p className={`${searchStyles.tableData} ${styles.parentSearchResultData}`}>
              {d.name ? d.name : '-'}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

DogAutocompleteSearch.defaultProps = {
  className: undefined,
  defaultValue: undefined,
  error: undefined,
};

export default DogAutocompleteSearch;
