import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import Pagination from '@mui/material/Pagination';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import searchStyles from '../../../styles/search.module.css';
import registerService from '../../../services/registerService';
import { BaseDog, SearchControlParams } from '../../../types';
import SearchControls from './SearchControls';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import TextInput from '../../inputs/TextInput';

function List() {
  const navigate = useNavigate();
  const windowDims = useWindowDimensions();
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const [[page, shouldSearch], setPage] = useState<[number, boolean]>([
    parseInt(urlSearchParams.get('page') || '1', 10),
    false,
  ]);
  const [queryParams, setQueryParams] = useState<SearchControlParams>({
    gender: (urlSearchParams.get('gender') || undefined) as SearchControlParams['gender'],
    registerType: (urlSearchParams.getAll('registerType') || undefined) as SearchControlParams['registerType'],
    breed: (urlSearchParams.get('breed') || undefined) as SearchControlParams['breed'],
    sortByField: (urlSearchParams.get('sortByField') || undefined) as SearchControlParams['sortByField'],
    sortByOrder: (urlSearchParams.get('sortByOrder') || undefined) as SearchControlParams['sortByOrder'],
  });
  const [availablePages, setAvailablePages] = useState<number>(
    parseInt(urlSearchParams.get('availablePages') || '1', 10),
  );
  const [results, setResults] = useState<BaseDog[]>([]);
  const [query, setQuery] = useState(urlSearchParams.get('query') || '');
  const [loading, setLoading] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const search = async (q: string, newPage: number) => {
    const { data } = await registerService.searchDogs(
      {
        query: q,
        ...queryParams,
        page: newPage,
      },
    );
    const { rows, pages } = data;
    setAvailablePages(pages);
    const definedParams = Object.fromEntries(
      Object.entries(queryParams).filter(([, v]) => v !== undefined && v !== null),
    );
    setUrlSearchParams({
      query: q,
      ...definedParams,
      page: newPage,
      availablePages,
    } as any);
    setPage([newPage, false]);
    return rows;
  };

  const callSearch = async (q: string, newPage: number) => {
    setLoading(true);
    const r = await search(q, newPage);
    setIsFirstLoad(false);
    setResults(r);
    setLoading(false);
  };

  useEffect(() => {
    const f = () => callSearch(query, isFirstLoad ? page : 1);
    const delayDebounceFn = setTimeout(f, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [
    queryParams.breed,
    queryParams.gender,
    queryParams.registerType,
    queryParams.sortByField,
    queryParams.sortByOrder,
    query,
  ]);

  useEffect(() => {
    if (!isFirstLoad && shouldSearch) callSearch(query, page);
  }, [page, shouldSearch]);

  const getParentInfo = (parent: BaseDog | null) => {
    const reg = parent?.spkyReg ? `(${parent.spkyReg})` : '';
    return parent ? `${parent?.name} ${reg}` : '-';
  };

  const isMobile = windowDims.width < 600;

  const damSireColumns: GridColDef[] = useMemo(
    () => (!isMobile ? [
      {
        field: 'sire',
        headerName: 'Isä',
        flex: 1,
        editable: false,
        sortable: false,
        disableColumnMenu: true,
        valueGetter: (params: any) => getParentInfo(params.row.sire),
      },
      {
        field: 'dam',
        headerName: 'Emä',
        flex: 1,
        editable: false,
        sortable: false,
        disableColumnMenu: true,
        valueGetter: (params: any) => getParentInfo(params.row.dam),
      },
    ] : []),
    [isMobile],
  );

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'name',
      headerName: 'Nimi',
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.name,
    },
    {
      field: 'spkyReg',
      headerName: 'SPKY rek.no',
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.spkyReg,
    },
    {
      field: 'birthday',
      headerName: 'Syntymäaika',
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      valueGetter: (params) => (params.row.birthday ? new Date(params.row.birthday).toLocaleDateString('fi-FI') : '-'),
    },
    ...damSireColumns,
    {
      field: 'view',
      headerName: '',
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <Button variant="outlined" onClick={() => navigate(`/register/${params.row.id}`)}>
          <ArrowForwardIos />
        </Button>
      ),
      editable: false,
    },
  ], [isMobile]);

  return (
    <div className={searchStyles.formWrapper}>
      <div className={searchStyles.searchWrapper}>
        <TextInput
          placeholder="Etsi koiraa nimellä tai rek.no:lla"
          error={false}
          setValue={setQuery}
          defaultValue={query}
          white
          label="Etsi koiraa nimellä tai rek.no:lla"
          id="search-input"
        />
        <SearchControls controls={queryParams} setControls={setQueryParams} isFull={false} />
      </div>
      <Pagination
        onChange={(_, p) => setPage([p, true])}
        sx={{ marginTop: '20px', marginBottom: '20px' }}
        count={availablePages}
        page={page}
        variant="outlined"
        shape="rounded"
      />
      <DataGrid
        rows={results}
        getRowHeight={() => 'auto'}
        aria-label="Rekisteri"
        sortingMode="server"
        paginationMode="server"
        sortModel={[{
          field: queryParams.sortByField || 'id',
          sort: queryParams.sortByOrder || 'desc',
        }]}
        rowCount={availablePages * 30}
        pagination
        paginationModel={{ page, pageSize: 30 }}
        pageSizeOptions={[30]}
        loading={loading}
        hideFooterPagination
        onSortModelChange={(model) => {
          if (model.length === 0) {
            setQueryParams({ ...queryParams, sortByField: undefined, sortByOrder: undefined });
            return;
          }
          const sortBy = model[0];
          setQueryParams({
            ...queryParams,
            sortByField: (sortBy.field as SearchControlParams['sortByField']),
            sortByOrder: sortBy.sort as SearchControlParams['sortByOrder'],
          });
        }}
        columns={columns}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
          '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
          '& .MuiDataGrid-virtualScroller': { minHeight: '100px' },
        }}
      />
      <Pagination
        siblingCount={0}
        boundaryCount={2}
        onChange={(_, p) => setPage([p, true])}
        sx={{ marginTop: '20px', marginBottom: '20px' }}
        count={availablePages}
        page={page}
        variant="outlined"
        shape="rounded"
      />
    </div>
  );
}

export default List;
