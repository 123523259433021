import React, { useEffect, useState } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import fiLocale from 'date-fns/locale/fi';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import styles from '../../styles/dogForm.module.css';

type Props = {
  label?: string | undefined,
  setValue: (arg: Date | undefined) => void,
  info?: string,
  error: boolean | undefined,
  defaultValue?: string | undefined,
  className?: string
};

function DateInput({
  label, setValue, info, error, defaultValue, className,
}: Props) {
  const [startDate, setStartDate] = useState<Date | null>(null);
  useEffect(() => {
    if (defaultValue) {
      const date = new Date(defaultValue);
      if (startDate?.getTime() !== date.getTime()) {
        setStartDate(date);
        setValue(date);
      }
    }
  }, [defaultValue]);

  return (
    <div
      className={`${styles.formInputBlock} ${className} date-input-block`}
      style={
      error ? { borderBottom: '1px solid red', borderRadius: 5 } : {}
    }
    >
      {info && (
        <p className={styles.inputInfo}>{info}</p>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={fiLocale}>
        <DatePicker
          className={className}
          value={startDate}
          maxDate={new Date()}
          minDate={new Date('1900-01-01')}
          label={label}
          inputFormat="dd.MM.yyyy"
          mask="__.__.____"
          onChange={(date: Date | null) => {
            const overMaxDate = date && date.getTime() > new Date().getTime();
            const isInvalidDate = !date || overMaxDate || (
              date instanceof Date && Number.isNaN(date.getTime())
            );
            if (!isInvalidDate) {
              setStartDate(date);
              setValue(date);
            } else if (date === null) {
              setStartDate(null);
              setValue(undefined);
            }
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          renderInput={(params: any) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </div>
  );
}

DateInput.defaultProps = {
  info: '',
  defaultValue: undefined,
  label: undefined,
  className: '',
};

export default DateInput;
