import React from 'react';
import TextField from '@mui/material/TextField';
import styles from '../../styles/dogForm.module.css';

type Props = {
  label: string,
  setValue: (arg: string) => void,
  placeholder?: string,
  info?: string,
  white?: boolean,
  className?: string,
  error: boolean,
  required?: boolean,
  defaultValue?: string | undefined,
  textArea?: boolean,
  type?: 'password' | 'text' | 'email' | 'tel',
  autoComplete?: 'username' | 'current-password' | 'new-password',
  id?: string
};

function TextInput({
  label,
  setValue,
  placeholder,
  info,
  white,
  error,
  className,
  defaultValue,
  required,
  textArea,
  type,
  autoComplete,
  id,
}: Props) {
  let blockClass = styles.formInputBlock;
  if (className) blockClass += ` ${className}`;
  return (
    <div className={blockClass}>
      <TextField
        placeholder={placeholder}
        error={error}
        sx={{
          backgroundColor: 'white',
        }}
        multiline={textArea}
        minRows={textArea ? 4 : 0}
        required={required}
        value={defaultValue ?? ''}
        label={label}
        variant={white ? 'outlined' : 'filled'}
        onChange={(ev) => setValue(ev.target.value)}
        helperText={info || undefined}
        type={type}
        autoComplete={autoComplete}
        id={id}
      />
    </div>
  );
}

TextInput.defaultProps = {
  info: undefined,
  white: false,
  required: false,
  className: undefined,
  defaultValue: undefined,
  placeholder: undefined,
  autoComplete: undefined,
  textArea: false,
  type: 'text',
  id: undefined,
};

export default TextInput;
