import React, { useEffect, useState } from 'react';
import formStyles from '../../../styles/dogForm.module.css';
import appStyles from '../../../styles/app.module.css';
import Notification from '../../misc/Notification';
import TextInput from '../../inputs/TextInput';
import Guide from '../../misc/Guide';
import useForm from '../../../hooks/useForm';
import dogValidators from '../../../validators/dogValidators';
import { Breed, NewDog, NewFamilyTreeReg } from '../../../types';
import useNotification from '../../../hooks/useNotification';
import SelectInput from '../../inputs/SelectInput';
import constants from '../../../utils/constants';
import DogAutocompleteSearch from '../../inputs/DogAutocompleteSearch';
import SaveButton from '../../misc/SaveButton';
import DateInput from '../../inputs/DateInput';
import ConfirmPurchase from '../../payments/ConfirmPurchase';

function FamilyTreeRegistration() {
  const { notification, showNotification, notificationType } = useNotification();
  const urlParams = new URLSearchParams(window.location.search);
  const [submitted, setSubmitted] = useState<boolean>(urlParams.get('submitted') === 'true');

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const submittedParam = searchParams.get('submitted');
    const isSubmitted = submittedParam === 'true';
    setSubmitted(isSubmitted);
  }, [urlParams]);

  const setSubmittedWithUrl = (value: boolean) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('submitted', value.toString());
    setSubmitted(value);
    if (value) {
      window.history.pushState({}, '', `${window.location.pathname}?${searchParams}`);
    } else {
      window.history.replaceState({}, '', window.location.pathname);
    }
  };

  const {
    updateField, submit, errors, isLoading, values,
  } = useForm({
    validator: dogValidators.validateFamilyTreeReg,
    submitter: async () => {
      // Using a slight delay to indicate state change
      // to the user before the next step
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 1500));
      setSubmittedWithUrl(true);
    },
  });

  const updateDogField = (fieldKey: keyof NewFamilyTreeReg) => updateField(fieldKey);

  useEffect(() => {
    const hasError = Object.values(errors).reduce((p, n) => p || n, false);
    if (hasError) showNotification('Tarkista puuttuvat kentät', 'error');
  }, [errors]);

  if (submitted) {
    return (
      <ConfirmPurchase
        registrationData={{
          type: 'OTHER',
          data: values as NewDog,
        }}
        onClose={() => setSubmittedWithUrl(false)}
      />
    );
  }

  const inputClass = `${formStyles.sukupuuFormBlock} ${formStyles.sukupuuLeftBlock}`;

  // Used to restrict parent search to only Bordercollie and Kelpie,
  // if the registered dog is one of them
  const parentSearchBreed: Breed | undefined = ['Bordercollie', 'Working kelpie'].includes(values.breed)
    ? values.breed : undefined;

  return (
    <div className={formStyles.formWrapper}>
      {notification && <Notification message={notification} type={notificationType} /> }
      <form className={formStyles.form}>
        <div className={`${formStyles.formGroup} ${formStyles.sukupuuFormGroup}`}>
          <h2 className={appStyles.pageHeader}>Lisää sukupuukoira</h2>
          <Guide
            label="Lisää sukupuukoira koiran vanhemmaksi"
            message="Sukupuukoira on rekisteröitävän koiran emä/isä.
            Lisää sukupuukoira ainoastaan, mikäli se ei ole jo tietokannassa.
            Sukupuukoiran lisääminen on ilmaista."
          />
          <div className={formStyles.formRow}>
            <SelectInput
              label="Rotu"
              className={`${formStyles.sukupuuFormBlock} breed-input`}
              setValue={updateDogField('breed')}
              options={constants.BREED_TYPES.map((b) => [b, b])}
              defaultValue={values.breed}
              error={errors.breed}
            />
            <TextInput
              required
              label="Nimi"
              className={`${formStyles.sukupuuFormBlock} name-input`}
              placeholder="Koiran nimi"
              setValue={updateDogField('name')}
              defaultValue={values.name}
              error={errors.name}
              id="name-input"
            />
          </div>
          <div className={formStyles.formRow}>
            <TextInput
              required
              label="Rekisterinumero"
              className={`${inputClass} second-reg-input`}
              placeholder="Rekisterinumero"
              setValue={updateDogField('secondReg')}
              defaultValue={values.secondReg}
              error={errors.secondReg}
              info="Esim. ISDS tai ER rekisterinumero"
            />
            <SelectInput
              label="Sukupuoli"
              className={`${formStyles.sukupuuFormBlock} gender-input`}
              setValue={updateDogField('gender')}
              options={constants.GENDER_TYPES.map((g) => [g, g])}
              defaultValue={values.gender}
              error={errors.gender}
            />
          </div>
          <div className={formStyles.formRow}>
            <DateInput
              setValue={updateDogField('birthday')}
              defaultValue={values.birthday}
              label="Syntymäaika"
              error={errors.birthday}
              className={`${formStyles.sukupuuFormBlock} birthday-input`}
            />
            <TextInput
              label="Väri"
              className={`${formStyles.sukupuuFormBlock} color-input`}
              placeholder="Väri"
              setValue={updateDogField('color')}
              defaultValue={values.color}
              error={errors.color}
            />
          </div>
          <div>
            <span style={{ fontSize: 14, color: '#333' }}>
              Kirjaa koiran vanhemmat vain jos ne löytyvät jo tietokannasta.
            </span>
          </div>
          <div className={formStyles.formRow}>
            <DogAutocompleteSearch
              label="Isä"
              className={`${formStyles.sukupuuFormBlock} sire-input`}
              placeholder="Hae nimellä tai rek.nolla"
              searchOptions={{ gender: 'Uros', breed: parentSearchBreed }}
              setValue={updateDogField('sireId')}
              defaultValue={values.sireId}
              error={errors.sireId}
            />
            <DogAutocompleteSearch
              label="Emä"
              className={`${inputClass} dam-input`}
              placeholder="Hae nimellä tai rek.nolla"
              searchOptions={{ gender: 'Narttu', breed: parentSearchBreed }}
              setValue={updateDogField('damId')}
              defaultValue={values.damId}
              error={errors.damId}
            />
          </div>
          <SaveButton
            className="send-button"
            isLoading={isLoading}
            text="Jatka tietojen tarkistukseen"
            onClick={submit}
            size="large"
            variant="contained"
          />
        </div>
      </form>
    </div>
  );
}

export default FamilyTreeRegistration;
