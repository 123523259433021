import { useState, ChangeEvent, useEffect } from 'react';
import { SearchOptions } from '../types';

type Props = {
  searchFunction: (query: any) => Promise<any[]>
  initialQuery?: string
  searchOptions?: SearchOptions
};

const WAIT_BEFORE_SEND = 1000;

function useAutocomplete({ searchFunction, initialQuery, searchOptions }: Props) {
  const [results, setResults] = useState<any[]>([]);
  const [query, setQuery] = useState(initialQuery || '');
  const [loading, setLoading] = useState(false);

  const search = async () => {
    setLoading(true);
    setResults([]);
    const r = await searchFunction(query);
    setLoading(false);
    setResults(r);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(search, WAIT_BEFORE_SEND);
    return () => clearTimeout(delayDebounceFn);
  }, [query, searchOptions]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  return {
    results, onChange, query, setQuery, loading, forceSearch: search,
  };
}

export default useAutocomplete;
