import React, { useEffect, useState } from 'react';
import treeStyles from '../../../styles/famTree.module.css';
import appStyles from '../../../styles/app.module.css';
import styles from '../../../styles/dogForm.module.css';
import viewStyles from '../../../styles/viewDog.module.css';
import registerService from '../../../services/registerService';
import { BaseDog, PublicDogWithParents, FamilyTreeDog } from '../../../types';

type Props = {
  dog: PublicDogWithParents,
};

function FamilyTree({ dog }: Props) {
  const [puppies, setPuppies] = useState<FamilyTreeDog[]>([]);

  useEffect(() => {
    const getPuppies = async (id: string) => {
      const { data, success } = await registerService.getPuppiesOfDog(id);
      if (success) setPuppies(data);
    };
    getPuppies(dog.id.toString());
  }, [dog]);

  const parentInfo = (parent: BaseDog | undefined, type: 'dam' | 'sire') => (
    <div>
      <p style={{ fontWeight: 'bold' }}>{type === 'dam' ? 'Emä' : 'Isä'}</p>
      {parent ? (
        <div className={styles.familyDog}>
          <div className={styles.familyDogName}>
            <p className={styles.familyTreeDogName}>{parent.name}</p>
            <p className={styles.familyTreeDogName}>
              {parent.spkyReg ?? parent.secondReg}
            </p>
          </div>
          <a className={appStyles.link} href={`/register/${parent.id}`}>
            <button className={appStyles.listButton} type="submit">
              Tarkastele
            </button>
          </a>
        </div>
      ) : (
        <p>Ei tiedossa</p>
      )}
    </div>
  );

  return (
    <div className={`${viewStyles.infoTable} ${viewStyles.sukupuuInfoTable}`}>
      <h1 className={appStyles.pageHeader}>Sukupuu</h1>
      <div className={treeStyles.familyTreeWrapper}>
        <div>
          <div style={{ background: '#eee', padding: '10px', borderRadius: '10px' }}>
            <div className={styles.damSireBlock}>
              {parentInfo(dog.dam, 'dam')}
              {parentInfo(dog.sire, 'sire')}
            </div>
          </div>
          {puppies ? (
            <div style={{
              background: '#eee', padding: '10px', borderRadius: '10px', marginTop: '20px',
            }}
            >
              <p style={{ fontWeight: 'bold' }}>Pennut</p>
              {puppies.map((d) => (
                <div className={styles.damSireBlock} key={d.id}>
                  <div className={styles.familyDog}>
                    <div className={styles.familyDogName}>
                      <p className={styles.familyTreeDogName}>{d.name}</p>
                      <p className={styles.familyTreeDogName}>
                        {d.spkyReg || d.secondReg}
                      </p>
                    </div>
                    <a className={appStyles.link} href={`/register/${d.id}`}>
                      <button className={appStyles.listButton} type="submit">
                        Tarkastele
                      </button>
                    </a>
                  </div>
                  <br />
                </div>
              ))}
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
}

export default FamilyTree;
