import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import React from 'react';

type Props = {
  text: string,
  isLoading?: boolean,
  size: 'large' | 'medium',
  variant: 'contained' | 'outlined',
  className?: string
  onClick: () => void,
  type?: 'button' | 'submit' | 'reset'
  style?: any
};

function SaveButton({
  text, isLoading, onClick, size, variant, className, type, style,
}: Props) {
  return (
    <Stack style={style} direction="row" spacing={2}>
      <LoadingButton
        style={style}
        onClick={onClick}
        size={size}
        loading={isLoading}
        loadingIndicator="Ladataan..."
        variant={variant}
        className={className}
        type={type}
      >
        {text}
      </LoadingButton>
    </Stack>
  );
}

SaveButton.defaultProps = {
  isLoading: false,
  className: undefined,
  type: 'button',
  style: {},
};

export default SaveButton;
