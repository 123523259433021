import React, { useEffect } from 'react';
import SelectInput from '../../inputs/SelectInput';
import formStyles from '../../../styles/dogForm.module.css';
import appStyles from '../../../styles/app.module.css';
import Notification from '../../misc/Notification';
import TextInput from '../../inputs/TextInput';
import DateInput from '../../inputs/DateInput';
import ParentSearchInput from '../../inputs/DogAutocompleteSearch';
import Guide from '../../misc/Guide';
import useForm from '../../../hooks/useForm';
import dogValidators from '../../../validators/dogValidators';
import registerService from '../../../services/registerService';
import { Breed, NewLightReg } from '../../../types';
import useNotification from '../../../hooks/useNotification';
import SaveButton from '../../misc/SaveButton';
import constants from '../../../utils/constants';

function LightRegistration() {
  const { notification, showNotification, notificationType } = useNotification();
  const responseHandler = (response: any) => {
    const { success, error } = response;
    if (success) {
      showNotification('Koira luotu onnistuneesti');
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      showNotification(error, 'error');
    }
  };

  const {
    updateField, submit, errors, isLoading, values,
  } = useForm({
    validator: dogValidators.validateLightReg,
    submitter: registerService.createOtherRegisterdog('Muu rekisteri'),
    responseHandler,
  });

  const updateDogField = (fieldKey: keyof NewLightReg) => updateField(fieldKey);

  useEffect(() => {
    const hasError = Object.values(errors).reduce((p, n) => p || n, false);
    if (hasError) showNotification('Tarkista puuttuvat kentät', 'error');
  }, [errors]);

  const inputClass = `${formStyles.sukupuuFormBlock} ${formStyles.sukupuuLeftBlock}`;

  // Used to restrict parent search to only Bordercollie and Kelpie,
  // if the registered dog is one of them
  const parentSearchBreed: Breed | undefined = ['Bordercollie', 'Working kelpie'].includes(values.breed)
    ? values.breed : undefined;

  return (
    <div className={formStyles.formWrapper}>
      {notification && <Notification message={notification} type={notificationType} /> }
      <form className={formStyles.form}>
        <div className={`${formStyles.formGroup} ${formStyles.sukupuuFormGroup}`}>
          <h2 className={appStyles.pageHeader}>Kilpailukoiran kevytrekisteröinti</h2>
          <Guide
            label="Huomioitavaa"
            message="Kilpaileva koira täytyy rekisteröidä vain mikäli sitä ei ole tietokannassa. Mikäli Emä ja/tai Isä ei löydy tietokannasta, on ne ensin lisättävä Lisää sukupuukoira -toiminnolla."
          />
          <div className={formStyles.formRow}>
            <SelectInput
              label="Rotu"
              setValue={updateDogField('breed')}
              options={constants.BREED_TYPES.map((b) => [b, b])}
              defaultValue={values.breed}
              error={errors.breed}
              className={`${formStyles.sukupuuFormBlock} breed-input`}
            />
            <TextInput
              required
              label="Nimi"
              className={`${formStyles.sukupuuFormBlock} name-input`}
              placeholder="Koiran nimi"
              setValue={updateDogField('name')}
              defaultValue={values.name}
              error={errors.name}
            />
          </div>
          <div className={formStyles.formRow}>
            <TextInput
              required
              label="Rekisterinumero"
              className={`${inputClass} second-reg-input`}
              placeholder="Rekisterinumero"
              setValue={updateDogField('secondReg')}
              defaultValue={values.secondReg}
              error={errors.secondReg}
              info="Esim. ISDS tai ER rekisterinumero"
            />
            <SelectInput
              label="Sukupuoli"
              options={constants.GENDER_TYPES.map((gender) => [gender, gender])}
              setValue={updateDogField('gender')}
              error={errors.gender}
              defaultValue={values.gender ?? undefined}
              className={`${formStyles.sukupuuFormBlock} gender-input`}
            />
          </div>
          <div className={formStyles.formRow}>
            <DateInput
              setValue={updateDogField('birthday')}
              defaultValue={values.birthday}
              label="Syntymäaika *"
              error={errors.birthday}
              className={`${formStyles.sukupuuFormBlock} birthday-input`}
            />
            <TextInput
              label="Väri"
              className={`${formStyles.sukupuuFormBlock} color-input`}
              placeholder="Väri"
              setValue={updateDogField('color')}
              defaultValue={values.color}
              error={errors.color}
            />
          </div>
          <div className={formStyles.formRow}>
            <ParentSearchInput
              label="Isä *"
              className={`${formStyles.sukupuuFormBlock} sire-input`}
              placeholder="Hae nimellä tai rek.nolla"
              searchOptions={{ gender: 'Uros', breed: parentSearchBreed }}
              setValue={updateDogField('sireId')}
              defaultValue={values.sireId}
              error={errors.sireId}
            />
            <ParentSearchInput
              label="Emä *"
              className={`${inputClass} dam-input`}
              placeholder="Hae nimellä tai rek.nolla"
              searchOptions={{ gender: 'Narttu', breed: parentSearchBreed }}
              setValue={updateDogField('damId')}
              defaultValue={values.damId}
              error={errors.damId}
            />
          </div>
          <SaveButton
            className="send-button"
            isLoading={isLoading}
            text="Lähetä"
            onClick={submit}
            size="large"
            variant="contained"
          />
        </div>
      </form>
    </div>
  );
}

export default LightRegistration;
