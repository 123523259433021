import React, { useEffect, useState } from 'react';
import {
  Page, Text, View, Image, Document, StyleSheet, PDFViewer, PDFDownloadLink,
} from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { Download } from '@mui/icons-material';
import { FamilyTreeDog, PublicDogWithParents } from '../../../types';
import registerService from '../../../services/registerService';
import requestFields from '../../../utils/requestFields';

const styles = StyleSheet.create({
  viewer: {
    width: '100%',
    maxWidth: '900px',
    height: '700px',
    maxHeight: '70vh',
    margin: '0 auto',
    marginTop: '40px',
    backgroundColor: '#FFF',
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFF',
    position: 'relative',
  },
  section: {
    margin: 10,
    width: '100%',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  marginLeftBorder: {
    borderBottom: '1px solid #000',
    width: '20%',
    height: '50px',
  },
  marginLeftLogo: {
    width: '15%',
    height: 'auto',
    position: 'absolute',
    left: '0',
    marginLeft: '10px',
  },
  marginLeft: {
    width: '20%',
    height: '50px',
  },
  content: {
    border: '1px solid #000',
    borderTop: '0px',
    width: '80%',
    padding: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '50px',
  },
  contentBlock: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '120px',
    maxWidth: '75%',
  },
  firstGenContent: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '50px',
  },
  genContent: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '80px',
  },
  placeholder: {
    border: '1px solid black',
    borderTop: '0',
    width: '80%',
    padding: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '70px',
  },
  heading: {
    fontSize: '12px',
    marginTop: '5px',
    marginBottom: '5px',
    color: 'black',
  },
  tableHeader: {
    fontSize: '16px',
    marginBottom: '20px',
  },
  tableLabel: {
    fontSize: '12px',
    marginBottom: '10px',
    color: 'black',
  },
  tableLabelSmall: {
    fontSize: '10px',
    marginBottom: '3px',
    color: 'black',
  },
  signLine: {
    fontSize: '12px',
    marginBottom: '10px',
    color: 'black',
    marginTop: '20px',
  },
  tableData: {
    marginTop: '5px',
    fontSize: '9px',
    color: '#333',
    minWidth: '70px',
  },
  tableDataSmall: {
    marginTop: '3px',
    fontSize: '7px',
    color: '#333',
    minWidth: '70px',
  },
  link: {
    marginTop: '100px',
  },
  familyTree: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  layer: {
    display: 'flex',
    flexDirection: 'column',
    width: '25%',
    height: '100%',
  },
  layerContent: {
    margin: '0',
    padding: '0 0 0 10px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '50%',
    border: '1px solid grey',
    background: '#fff',
    fontSize: '10px',
  },
  familyTreePage: {
    margin: '0px',
    width: '100%',
    height: '520px',
  },
});

type Props = {
  dog: PublicDogWithParents,
  layers: FamilyBoardLayers,
};

function getDocument({ dog, layers }: Props) {
  const getDisplayReg = (d: FamilyTreeDog | undefined) => {
    if (!d) return '-';
    return d.spkyReg || d.secondReg;
  };

  const getDisplayName = (d: FamilyTreeDog | undefined) => {
    if (d) {
      return <a href={`/register/${d.id}`}>{d.name}</a>;
    }
    return <p>Ei tiedossa</p>;
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.tableHeader}>SUOMEN PAIMENKOIRAYHDISTYS RY. REKISTERITODISTUS</Text>
          <View style={styles.wrapper}>
            <View style={styles.marginLeftLogo}>
              <Image src="/spky-uusi-logo-transparent.png" />
            </View>

            <View style={styles.marginLeft}>
              <Text style={styles.tableLabel} />
            </View>
            <View style={styles.content}>
              <View>
                <Text style={styles.tableLabel}>Rotu</Text>
                <Text style={{ ...styles.tableData, fontSize: '13px' }}>{ dog.breed }</Text>
              </View>
              <View>
                <Text style={styles.tableLabel}>Väritys</Text>
                <Text style={{ ...styles.tableData, fontSize: '13px' }}>{ dog.color }</Text>
              </View>
              <View>
                <Text style={styles.tableLabel}>Synt.aika</Text>
                <Text style={{ ...styles.tableData, fontSize: '13px' }}>{ dog.birthday ? new Date(dog.birthday).toLocaleDateString('fi') : '-' }</Text>
              </View>
              <View>
                <Text style={styles.tableLabel}>Sukupuoli</Text>
                <Text style={{ ...styles.tableData, fontSize: '13px' }}>{ dog.gender }</Text>
              </View>
            </View>

            <View style={styles.marginLeft} />
            <View style={styles.content}>
              <View>
                <Text style={styles.tableLabel}>Nimi</Text>
                <Text style={{ ...styles.tableData, fontSize: '13px' }}>{ dog.name }</Text>
              </View>
              <View>
                <Text style={styles.tableLabel}>SPKY rek. num.</Text>
                <Text style={{ ...styles.tableData, fontSize: '13px' }}>{ dog.spkyReg }</Text>
              </View>
              <View>
                <Text style={styles.tableLabel}>ID-Numero</Text>
                <Text style={{ ...styles.tableData, fontSize: '13px' }}>{ dog.idtm }</Text>
              </View>
            </View>
          </View>
          <View style={styles.wrapper}>
            <View style={{ ...styles.content, width: '100%', borderTop: '1px solid #000' }}>
              <View style={styles.contentBlock}>
                <Text style={styles.tableLabel}>Omistaja</Text>
                <Text style={styles.tableData}>{ dog.ownerName ?? '-' }</Text>
                <Text style={styles.tableData}>{ dog.ownerAddress ?? '-' }</Text>
              </View>
              <View style={styles.contentBlock}>
                <Text style={styles.tableLabel}>Kasvattaja</Text>
                <Text style={styles.tableData}>{ dog.breeder ?? '-' }</Text>
                <Text style={styles.tableData}>{ dog.breederAddress ?? '-' }</Text>
              </View>
            </View>

            <View style={{ ...styles.content, width: '100%' }}>
              <View style={styles.contentBlock}>
                <Text style={styles.tableLabel}>Muut tiedot</Text>
                <Text style={styles.tableData}>{ dog.otherInfo ? `${dog.otherInfo.slice(0, 220)}${dog.otherInfo.length > 220 ? '...' : ''}` : '-'}</Text>
              </View>
              <View style={styles.contentBlock}>
                <Text style={styles.tableLabel}>Muut rek. numerot</Text>
                <Text style={styles.tableData}>{ dog.secondReg ?? '-' }</Text>
              </View>
            </View>
          </View>

          <View style={styles.wrapper}>
            <View style={{ ...styles.marginLeft, height: '10px', paddingTop: '10px' }}>
              <Text style={styles.tableLabel}>Suomen Paimenkoirayhdistys ry. puolesta:</Text>
            </View>
            <View style={styles.placeholder}>
              <View>
                <Text style={styles.signLine}>___________________________</Text>
                <Text style={styles.tableData}>Paikka ja aika</Text>
              </View>
              <View>
                <Text style={styles.signLine}>___________________________</Text>
                <Text style={styles.tableData}>Allekirjoitus</Text>
              </View>
            </View>
          </View>
          <View style={styles.familyTreePage}>
            <Text style={styles.heading}>Sukutaulu, jossa isä ylempänä ja emä alempana.</Text>
            <View style={styles.familyTree}>
              <View style={styles.layer}>
                { layers[0].map((d, i) => (
                  <View key={d?.id ?? `l0-${i}`} style={styles.layerContent}>
                    <Text style={styles.tableLabelSmall}>{ getDisplayName(d) }</Text>
                    <Text style={styles.tableDataSmall}>{ getDisplayReg(d) }</Text>
                  </View>
                ))}
              </View>
              <View style={styles.layer}>
                { layers[1].map((d, i) => (
                  <View key={d?.id ?? `l1-${i}`} style={styles.layerContent}>
                    <Text style={styles.tableLabelSmall}>{ getDisplayName(d) }</Text>
                    <Text style={styles.tableDataSmall}>{ getDisplayReg(d) }</Text>
                  </View>
                ))}
              </View>
              <View style={styles.layer}>
                { layers[2].map((d, i) => (
                  <View key={d?.id ?? `l2-${i}`} style={styles.layerContent}>
                    <Text style={styles.tableLabelSmall}>{ getDisplayName(d) }</Text>
                    <Text style={styles.tableDataSmall}>{ getDisplayReg(d) }</Text>
                  </View>
                ))}
              </View>
              <View style={styles.layer}>
                { layers[3].map((d, i) => (
                  <View key={d?.id ?? `l3-${i}`} style={styles.layerContent}>
                    <Text style={styles.tableLabelSmall}>{ getDisplayName(d) }</Text>
                    <Text style={styles.tableDataSmall}>{ getDisplayReg(d) }</Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

type FamilyBoardLayers = (FamilyTreeDog | undefined)[][];

function DogPdf() {
  const { dogId }: { dogId?: string } = useParams();
  const [dog, setDog] = useState<PublicDogWithParents | undefined>(undefined);
  const [layers, setLayers] = useState<FamilyBoardLayers>();

  useEffect(() => {
    const getTree = async (id: string) => {
      await registerService.getDogFamilyTree(id).then((res) => {
        setLayers(res.data);
      });
    };
    if (dogId) getTree(dogId.toString());
  }, [dog]);

  useEffect(() => {
    const getDog = async (id: string) => {
      await registerService.getDog(id, requestFields.PDF_FIELDS).then((res) => {
        const { success, data } = res;
        if (success) {
          setDog(data);
        }
      });
    };
    if (dogId) getDog(dogId);
  }, [dogId]);

  if (!dog || !layers) return (<div />);
  return (
    <div style={{
      width: '100%', maxWidth: '1000px', padding: '10px', boxSizing: 'border-box', margin: '0 auto', marginTop: '40px',
    }}
    >
      <Button variant="outlined" endIcon={<Download />}>
        <PDFDownloadLink
          document={getDocument({ dog, layers })}
          fileName="koiran-tiedot-spky.pdf"
          style={{ textTransform: 'capitalize', textDecoration: 'none', color: 'initial' }}
        >
          {({ loading }) => (loading ? 'Ladataan dokumenttia...' : 'Lataa dokumentti')}
        </PDFDownloadLink>
      </Button>
      <br />
      <PDFViewer style={styles.viewer}>
        { getDocument({ dog, layers }) }
      </PDFViewer>
    </div>
  );
}

export default DogPdf;
