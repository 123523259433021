import React, { useEffect, useState } from 'react';
import styles from '../../../styles/dogForm.module.css';
import appStyles from '../../../styles/app.module.css';
import useNotification from '../../../hooks/useNotification';
import Notification from '../../misc/Notification';
import useForm from '../../../hooks/useForm';
import dogValidators from '../../../validators/dogValidators';
import { Breed, NewDog } from '../../../types';
import TextInput from '../../inputs/TextInput';
import DateInput from '../../inputs/DateInput';
import DogAutocompleteSearch from '../../inputs/DogAutocompleteSearch';
import SelectInput from '../../inputs/SelectInput';
import constants from '../../../utils/constants';
import SaveButton from '../../misc/SaveButton';
import ConfirmPurchase from '../../payments/ConfirmPurchase';

function RegisterDog() {
  const { notification, showNotification } = useNotification();
  const urlParams = new URLSearchParams(window.location.search);
  const [submitted, setSubmitted] = useState<boolean>(urlParams.get('submitted') === 'true');

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const submittedParam = searchParams.get('submitted');
    const isSubmitted = submittedParam === 'true';
    setSubmitted(isSubmitted);
  }, [urlParams]);

  const setSubmittedWithUrl = (value: boolean) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('submitted', value.toString());
    setSubmitted(value);
    if (value) {
      window.history.pushState({}, '', `${window.location.pathname}?${searchParams}`);
    } else {
      window.history.replaceState({}, '', window.location.pathname);
    }
  };

  const {
    updateField,
    submit,
    errors,
    values,
    isLoading,
  } = useForm({
    validator: dogValidators.validateCreateDog,
    submitter: async () => {
      // Using a slight delay to indicate state change
      // to the user before the next step
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 1500));
      setSubmittedWithUrl(true);
    },
  });

  const updateDogField = (fieldKey: keyof NewDog) => updateField(fieldKey);

  useEffect(() => {
    const hasError = Object.values(errors).reduce((p, n) => p || n, false);
    if (hasError) showNotification('Tarkista puuttuvat kentät');
  }, [errors]);

  if (submitted) {
    return (
      <ConfirmPurchase
        registrationData={{
          type: 'BASIC',
          data: { ...values, registerType: 'Perusrekisteri' } as NewDog,
        }}
        onClose={() => setSubmittedWithUrl(false)}
      />
    );
  }

  // Used to restrict parent search to only Bordercollie and Kelpie,
  // if the registered dog is one of them
  const parentSearchBreed: Breed | undefined = ['Bordercollie', 'Working kelpie'].includes(values.breed) ? values.breed : undefined;

  return (
    <div className={styles.formWrapper}>
      <form className={styles.form}>
        {notification && <Notification message={notification} type="error" /> }
        <div className={styles.formGroup}>
          <h2 className={appStyles.pageHeader} style={{ marginTop: -10 }}>Rekisteröi koira</h2>
          <h2 className={appStyles.pageHeader}>Koiran tiedot</h2>
          <SelectInput
            label="Rotu *"
            options={constants.BREED_TYPES.map((breed) => [breed, breed])}
            setValue={updateDogField('breed')}
            error={errors.breed}
            defaultValue={values.breed ?? undefined}
            className="breed-input"
          />
          <TextInput
            label="Rekisterinumerot"
            info="Rekisterinumerot (esim. ISDS, SKL.) pilkulla erotettuna"
            setValue={updateDogField('secondReg')}
            error={errors.secondReg}
            defaultValue={values.secondReg}
            className="reg-num-input"
          />
          <TextInput
            required
            label="Mikrosirunumero"
            info="Koiran tunnusmerkintä tai mikrosirun numero"
            setValue={updateDogField('idtm')}
            error={errors.idtm}
            defaultValue={values.idtm}
            className="idtm-input"
          />
          <TextInput
            required
            label="Koiran virallinen nimi"
            setValue={updateDogField('name')}
            error={errors.name}
            defaultValue={values.name}
            className="name-input"
          />
          <DateInput
            setValue={updateDogField('birthday')}
            label="Syntymäaika *"
            defaultValue={values.birthday}
            error={errors.birthday}
          />
          <TextInput
            required
            label="Kasvattajan nimi"
            placeholder="Etunimi Sukunimi"
            setValue={updateDogField('breeder')}
            error={errors.breeder}
            defaultValue={values.breeder}
            className="breeder-name-input"
          />
          <TextInput
            label="Kasvattajan paikkakunta"
            setValue={updateDogField('breederAddress')}
            error={errors.breederAddress}
            defaultValue={values.breederAddress}
            className="breeder-city-input"
          />
          <TextInput
            required
            label="Väri"
            setValue={updateDogField('color')}
            error={errors.color}
            defaultValue={values.color}
            className="color-input"
          />
          <SelectInput
            label="Sukupuoli *"
            options={constants.GENDER_TYPES.map((gender) => [gender, gender])}
            setValue={updateDogField('gender')}
            error={errors.gender}
            defaultValue={values.gender ?? undefined}
            className="gender-input"
          />
          <div>
            <span style={{ fontSize: 14, color: '#333' }}>
              Mikäli emä ja/tai isä ei löydy tietokannasta, on ne ensin lisättävä
              &apos;Lisää sukupuukoira&apos; -toiminnolla.
            </span>
          </div>
          <DogAutocompleteSearch
            defaultValue={values.sireId}
            setValue={updateDogField('sireId')}
            label="Isä *"
            placeholder="Isä"
            error={errors.sireId}
            searchOptions={{ gender: 'Uros', breed: parentSearchBreed }}
            className="sire-input"
          />
          <DogAutocompleteSearch
            defaultValue={values.damId}
            setValue={updateDogField('damId')}
            label="Emä *"
            placeholder="Emä"
            error={errors.damId}
            searchOptions={{ gender: 'Narttu', breed: parentSearchBreed }}
            className="dam-input"
          />
          <TextInput
            label="Lisätiedot (ei-julkinen viesti rekisterinpitäjälle)"
            placeholder="Esim. lonkka-, selkä-, olka- ja silmätulokset"
            setValue={updateDogField('otherInfoClassified')}
            error={errors.otherInfoClassified}
            defaultValue={values.otherInfoClassified}
            textArea
            className="other-info-classified-input"
          />
        </div>
        <div className={`${styles.formGroup} ${styles.altFormGroup}`}>
          <div
            className={styles.altFormContent}
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              height: '100%',
            }}
          >
            <div>
              <h2 className={appStyles.pageHeader}>Omistajan tiedot</h2>
              <TextInput
                white
                required
                label="Nimi"
                placeholder="Etunimi Sukunimi"
                setValue={updateDogField('ownerName')}
                error={errors.ownerName}
                defaultValue={values.ownerName}
                className="owner-name-input"
              />
              <TextInput
                white
                required
                label="Sähköposti"
                placeholder="Sähköposti"
                setValue={updateDogField('ownerEmail')}
                error={errors.ownerEmail}
                defaultValue={values.ownerEmail}
                className="owner-email-input"
              />
              <TextInput
                required
                white
                label="Puhelinnumero"
                placeholder="Puhelinnumero"
                setValue={updateDogField('ownerPhone')}
                error={errors.ownerPhone}
                defaultValue={values.ownerPhone}
                className="owner-phone-input"
              />
              <TextInput
                white
                required
                label="Osoite"
                placeholder="Katuosoite, postinumero"
                setValue={updateDogField('ownerAddress')}
                error={errors.ownerAddress}
                defaultValue={values.ownerAddress}
                className="owner-address-input"
              />
            </div>
            <SaveButton
              text="Jatka tietojen tarkistukseen"
              size="large"
              variant="contained"
              onClick={submit}
              isLoading={isLoading}
              style={{ width: '100%' }}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

RegisterDog.defaultProps = {
  dogId: undefined,
};

export default RegisterDog;
