// NOTE: These products are also configured in the backend. These
// should be kept in sync. The frontend is only used for displaying.

type DisplayProduct = {
  title: string,
  price: number,
};

const PUPPY_REG: DisplayProduct = {
  title: 'Penturekisteröinti',
  price: 14,
};

const DOG_REG: DisplayProduct = {
  title: 'Koiran rekisteröinti',
  price: 17,
};

const FAMILYTREE_DOG_REG: DisplayProduct = {
  title: 'Sukupuukoiran rekisteröinti',
  price: 0,
};

const COMISSION: DisplayProduct = {
  title: 'Palvelumaksu',
  price: 3,
};

export default {
  PUPPY_REG,
  DOG_REG,
  FAMILYTREE_DOG_REG,
  COMISSION,
};
