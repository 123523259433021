import React from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import styles from '../../styles/misc.module.css';

type Props = {
  label: string,
  message: string,
};

function Guide({ label, message }: Props) {
  return (
    <div className={styles.guideWrapper}>
      <AiFillInfoCircle className={styles.icon} />
      <div className={styles.text}>
        <h2>{ label }</h2>
        <p>{ message }</p>
      </div>
    </div>
  );
}

export default Guide;
