import React from 'react';
import Button from '@mui/material/Button';
import { GridColDef } from '@mui/x-data-grid';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'email',
    headerName: 'Sähköposti',
    width: 200,
    minWidth: 150,
    flex: 1,
    editable: true,
    type: 'string',
  },
  {
    field: 'accepted',
    headerName: 'Hyväksytty',
    width: 120,
    editable: true,
    type: 'boolean',
  },
  {
    field: 'admin',
    headerName: 'Admin',
    width: 120,
    editable: true,
    type: 'boolean',
  },
  {
    field: 'firstName',
    headerName: 'Etunimi',
    width: 150,
    minWidth: 100,
    flex: 1,
    editable: true,
    type: 'string',
  },
  {
    field: 'lastName',
    headerName: 'Sukunimi',
    width: 150,
    minWidth: 100,
    flex: 1,
    editable: true,
    type: 'string',
  },
  {
    field: 'phone',
    headerName: 'Puhelin',
    width: 150,
    minWidth: 100,
    flex: 1,
    editable: true,
    type: 'string',
  },
  {
    field: 'delete',
    headerName: 'Poista',
    width: 200,
    sortable: false,
    renderCell: () => (
      <Button color="error" variant="outlined">Poista käyttäjä</Button>
    ),
  },
];

export default {
  columns,
};
