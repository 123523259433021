import React from 'react';
import {
  Navigate, RouteProps, useLocation,
} from 'react-router-dom';

type LoginProps = { children: any, isAuthenticated: boolean };
type PrivateProps = LoginProps & { redirectTo: string };

function PrivateRoute({ children, isAuthenticated, redirectTo }: PrivateProps) {
  const location = useLocation();
  const from = location.pathname;
  return isAuthenticated ? children : <Navigate to={redirectTo} state={{ from }} />;
}

function LoginRoute({
  children, isAuthenticated,
}: LoginProps & RouteProps) {
  const location = useLocation();
  const state = location.state as { from: string } ?? undefined;
  const from = state?.from ?? '/';
  return !isAuthenticated ? children : (
    <Navigate to={from} state={{ from: location.pathname }} />
  );
}

export default {
  LoginRoute,
  PrivateRoute,
};
