import React, {
  Dispatch, SetStateAction,
  useMemo,
} from 'react';
import {
  Switch,
  FormControlLabel,
  FormGroup,
  Stack,
  styled,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import formStyles from '../../../styles/dogForm.module.css';
import {
  Breed,
  FullSearchControlParams, Gender, RegisterType, SearchControlParams,
} from '../../../types';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

type GenderQuery = {
  key: 'gender',
  value: Gender | undefined
};

type RegisterTypeQuery = {
  key: 'registerType',
  value: RegisterType | RegisterType[] | undefined
};

type AcceptedQuery = {
  key: 'accepted',
  value: boolean | undefined
};

type CheckedByAdminQuery = {
  key: 'checkedByAdmin',
  value: boolean | undefined
};

type BreedQuery = {
  key: 'breed',
  value: Breed | undefined
};

type Query = GenderQuery | RegisterTypeQuery;
type FullQuery = Query | AcceptedQuery | CheckedByAdminQuery | BreedQuery;

type Props = {
  controls: FullSearchControlParams | SearchControlParams,
  setControls: Dispatch<SetStateAction<any>>,
  isFull: false,
};
type FullProps = {
  controls: FullSearchControlParams,
  setControls: Dispatch<SetStateAction<any>>,
  isFull: true
};

const ColoredSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#FFC206',
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: 'white',
        boxShadow: 'none',
        border: '1px solid rgba(142,142,142,0.3)',
      },
    },
  },
}));

function SearchControls({ controls, setControls, isFull }: Props | FullProps) {
  const windowDims = useWindowDimensions();

  const toggleParams = ({ key, value }: FullQuery | Query) => {
    setControls((prev: any) => {
      const prevVal = prev[key];
      if (prevVal === undefined || prevVal !== value) {
        return { ...prev, [key]: value, page: 1 };
      }
      return { ...prev, [key]: undefined, page: 1 };
    });
  };

  const isMobile = windowDims.width < 600;

  const chooseRegisterType = (event: SelectChangeEvent<string>) => {
    const registerType = event.target.value;
    let value: RegisterTypeQuery['value'];
    if (registerType !== 'Kaikki rekisterit') {
      value = registerType as RegisterTypeQuery['value'];
    }
    if (registerType === 'SPKY rekisterit') {
      value = ['Perusrekisteri', 'Jalostusrekisteri'];
    }
    toggleParams({ key: 'registerType', value });
  };

  const registerTypeToValue = (registerType: RegisterType | RegisterType[] | undefined) => {
    if (Array.isArray(registerType)) {
      if (registerType.length === 0) return 'Kaikki rekisterit';
      return 'SPKY rekisterit';
    }
    return registerType || 'Kaikki rekisterit';
  };

  const registerTypeValue = useMemo(() => registerTypeToValue(
    controls.registerType,
  ), [controls.registerType]);

  return (
    <FormGroup className={formStyles.switchWrapper} row style={{ width: '100%' }}>
      <Stack direction="column" spacing={1} style={{ width: '100%' }}>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={1} style={{ width: '100%' }}>
          <Select
            sx={{ width: isMobile ? '100%' : '50%' }}
            labelId="register-select-label"
            id="register-select"
            value={registerTypeValue}
            onChange={chooseRegisterType}
          >
            <MenuItem value="Kaikki rekisterit">Kaikki rekisterit</MenuItem>
            <MenuItem value="SPKY rekisterit">SPKY rekisterit</MenuItem>
            <MenuItem value="Jalostusrekisteri">Jalostusrekisteri</MenuItem>
            <MenuItem value="Muu rekisteri">Muu rekisteri</MenuItem>
          </Select>
          <Select
            sx={{ width: isMobile ? '100%' : '50%' }}
            labelId="gender-select-label"
            id="gender-select"
            value={(controls as FullSearchControlParams).gender || 'Kaikki sukupuolet'}
            onChange={(v) => toggleParams({
              key: 'gender',
              value: (v.target.value === 'Kaikki sukupuolet'
                ? undefined
                : v.target.value) as Gender,
            })}
          >
            <MenuItem value="Kaikki sukupuolet">Kaikki sukupuolet</MenuItem>
            <MenuItem value="Uros">Uros</MenuItem>
            <MenuItem value="Narttu">Narttu</MenuItem>
          </Select>
        </Stack>
        <Select
          sx={{ width: isMobile ? '100%' : '50%', margin: 0 }}
          labelId="breed-select-label"
          id="breed-select"
          value={(controls as FullSearchControlParams).breed || 'Kaikki rodut'}
          onChange={(v) => toggleParams({
            key: 'breed',
            value: (v.target.value === 'Kaikki rodut'
              ? undefined
              : v.target.value) as Breed,
          })}
        >
          <MenuItem value="Kaikki rodut">Kaikki rodut</MenuItem>
          <MenuItem value="Bordercollie">Bordercollie</MenuItem>
          <MenuItem value="Working kelpie">Working kelpie</MenuItem>
          <MenuItem value="Muu">Muu</MenuItem>
        </Select>
        {isFull && (
          <>
            <Stack direction={isMobile ? 'column' : 'row'} spacing={1} style={{ width: '100%' }}>
              <FormControlLabel
                sx={{ color: '#433C3C', width: isMobile ? '100%' : '50%', margin: 0 }}
                control={(
                  <ColoredSwitch
                    className="accepted-control"
                    onChange={() => toggleParams({ key: 'accepted', value: true })}
                    checked={(controls as FullSearchControlParams).accepted ?? false}
                  />
                )}
                label="Vain hyväksytyt"
              />
              <FormControlLabel
                sx={{ color: '#433C3C', width: isMobile ? '100%' : '50%', margin: 0 }}
                control={(
                  <ColoredSwitch
                    className="not-accepted-control"
                    onChange={() => toggleParams({ key: 'accepted', value: false })}
                  // We actually need to check if === false, since other options are
                  // "true" and "undefined" and here "undefined" also passes !accepted
                    checked={(controls as FullSearchControlParams).accepted === false ?? false}
                  />
                )}
                label="Vain hyväksyttävät"
              />
            </Stack>
            <Stack direction={isMobile ? 'column' : 'row'} spacing={1} style={{ width: '100%' }}>
              <FormControlLabel
                sx={{ color: '#433C3C', width: isMobile ? '100%' : '50%', margin: 0 }}
                control={(
                  <ColoredSwitch
                    className="not-checked-by-admin-control"
                    onChange={() => toggleParams({ key: 'checkedByAdmin', value: false })}
                    // We actually need to check if === false, since other options are
                    // "true" and "undefined" and here "undefined" also passes !accepted
                    checked={
                      (controls as FullSearchControlParams).checkedByAdmin === false ?? false
                    }
                  />
                )}
                label="Vain tarkastamattomat"
              />
            </Stack>
          </>
        )}
      </Stack>
    </FormGroup>
  );
}

export default SearchControls;
