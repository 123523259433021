import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from '../../styles/admin.module.css';
import Notification from '../misc/Notification';
import TextInput from '../inputs/TextInput';
import useForm from '../../hooks/useForm';
import useNotification from '../../hooks/useNotification';
import SaveButton from '../misc/SaveButton';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import validators from '../../validators/userValidators';
import authService from '../../services/authService';

function FormSentResponse() {
  return (
    <div className={styles.formWrapper} style={{ minHeight: '50vh' }}>
      <div className={styles.form}>
        <h3 className={styles.loginHeader}>
          Salasana vaihdettu
        </h3>
        <p className={styles.loginHeader}>
          Voit nyt kirjautua sisään uudella salasanallasi.
        </p>
      </div>
    </div>
  );
}

function ResetPassword() {
  const { notification, showNotification, notificationType } = useNotification();
  const [formSent, setFormSent] = useState(false);
  const { token }: { token?: string } = useParams();

  const responseHandler = (res: any) => {
    try {
      const { success, error } = res;
      if (success) {
        setFormSent(true);
      } else {
        showNotification(error ?? 'Linkki on vanhentunut, pyydä uusi salasanan vaihtolinkki', 'error');
      }
    } catch (e) {
      showNotification('Jokin meni pieleen', 'error');
    }
  };

  const {
    updateField, submit, errors, isLoading, values,
  } = useForm({
    validator: validators.validateNewPassword,
    submitter: (content: any) => authService.resetPassword({ ...content, token }),
    responseHandler,
  });

  useEffect(() => {
    const hasError = Object.values(errors).reduce((p, n) => p || n, false);
    if (hasError) showNotification('Varmista, että salasanat ovat samat', 'error');
  }, [errors]);

  const windowDims = useWindowDimensions();
  const isMobile = windowDims.width < 600;

  if (formSent) {
    return <FormSentResponse />;
  }

  return (
    <div className={styles.formWrapper}>
      {notification && <Notification message={notification} type={notificationType} /> }
      <form
        className={styles.form}
        style={{ maxWidth: 400 }}
        onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
      >
        <h3 className={styles.loginHeader}>Vaihda salasana</h3>
        <p className={styles.loginHeader}>
          Valitse uusi salasana, jolla kirjaudut jatkossa palveluun.
        </p>
        <TextInput
          label="Uusi salasana"
          placeholder="Uusi salasana"
          setValue={updateField('password')}
          defaultValue={values.password}
          error={errors.password}
          white={isMobile}
          className="password-input"
          autoComplete="new-password"
          type="password"
        />
        <TextInput
          label="Toista uusi salasana"
          placeholder="Toista uusi salasana"
          setValue={updateField('passwordRepeat')}
          defaultValue={values.passwordRepeat}
          error={errors.passwordRepeat}
          white={isMobile}
          className="password-input"
          autoComplete="new-password"
          type="password"
        />
        <SaveButton
          text="Vaihda salasana"
          size="medium"
          variant="contained"
          onClick={submit}
          isLoading={isLoading}
          type="submit"
        />
      </form>
    </div>
  );
}

export default ResetPassword;
