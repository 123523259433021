import axios from 'axios';
import {
  LitterInfo,
  NewDog, NewFamilyTreeReg, NewLightReg, PuppyInfo, RegisterType, SearchOptions,
} from '../types';
import requestUtils from '../utils/request';
import parsers from '../utils/parsers';

const { handleRequestError, getAuthConfig } = requestUtils;

const API_URL = '/api';

const fetchData = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const searchDogs = async (options: SearchOptions) => {
  const qp = parsers.parseQueryParamsToString(options);
  const params = new URLSearchParams(qp);
  const response = await axios.get(API_URL, {
    params,
  });
  return response.data;
};

const searchFullDogs = async (query: SearchOptions = {}) => {
  try {
    const qp = parsers.parseQueryParamsToString(query);
    const params = new URLSearchParams(qp);
    const authHeaders = getAuthConfig();
    const fullHeaders = {
      ...authHeaders.headers,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
    };
    const response = await axios.get(`${API_URL}/full`, {
      params,
      headers: fullHeaders,
    });
    return response.data;
  } catch (e: any) {
    return handleRequestError(e);
  }
};

const createDog = async (dog: NewDog | NewLightReg | NewFamilyTreeReg) => {
  try {
    const response = await axios.post(API_URL, dog, {
      ...getAuthConfig(),
    });
    return response.data;
  } catch (e: any) {
    return handleRequestError(e);
  }
};

const createLitter = async (litter: (LitterInfo & PuppyInfo)[]) => {
  try {
    const response = await axios.post(`${API_URL}/litter`, litter, {
      ...getAuthConfig(),
    });
    return response.data;
  } catch (e: any) {
    return handleRequestError(e);
  }
};

const updateDog = async (dog: { [key: string]: any }, id: string) => {
  try {
    const response = await axios.put(`${API_URL}/${id}`, dog, {
      ...getAuthConfig(),
    });
    return response.data;
  } catch (e: any) {
    return handleRequestError(e);
  }
};

const deleteDog = async (id: string) => {
  try {
    const response = await axios.delete(`${API_URL}/${id}`, {
      ...getAuthConfig(),
    });
    return response.data;
  } catch (e: any) {
    return handleRequestError(e);
  }
};

const completeLightRegistration = async (dog: NewDog, id: string) => {
  try {
    const response = await axios.put(`${API_URL}/complete-light-registration/${id}`, dog, {
      ...getAuthConfig(),
    });
    return response.data;
  } catch (e: any) {
    return handleRequestError(e);
  }
};

const curriedCompleteLightRegistration = (id: string) => (
  dog: NewDog,
) => completeLightRegistration(dog, id);

const getDogFamilyTree = async (dogId: string) => {
  try {
    const response = await axios.get(`${API_URL}/family/${dogId}`);
    return response.data;
  } catch (e: any) {
    return handleRequestError(e);
  }
};

const getPuppiesOfDog = async (dogId: string) => {
  try {
    const response = await axios.get(`${API_URL}/puppies/${dogId}`, {
    });
    return response.data;
  } catch (e: any) {
    return handleRequestError(e);
  }
};

const getDog = async (dogId: string, fields?: string[]) => {
  try {
    const response = await axios.get(`${API_URL}/${dogId}`, {
      params: {
        fields,
      },
    });
    return response.data;
  } catch (e: any) {
    return handleRequestError(e);
  }
};

const createOtherRegisterdog = (
  registerType: RegisterType,
) => (dog: NewLightReg | NewFamilyTreeReg) => {
  const data = { ...dog, registerType };
  return createDog(data);
};

export default {
  fetchData,
  createDog,
  updateDog,
  deleteDog,
  completeLightRegistration,
  createOtherRegisterdog,
  searchDogs,
  getDog,
  searchFullDogs,
  getDogFamilyTree,
  createLitter,
  curriedCompleteLightRegistration,
  getPuppiesOfDog,
};
