import { useState } from 'react';
import { FormErrors } from '../types';

type ValidatorResponse = {
  valid: boolean,
  errors: FormErrors
};

type Props = {
  validator?: (content: any) => ValidatorResponse,
  responseHandler?: (response: any) => any
  submitter: (content: any) => Promise<any> | void
};

function useForm({ validator, submitter, responseHandler }: Props) {
  const [content, setContent] = useState<{ [key: string]: any }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const updateField = (fieldKey: string) => (fieldValue: string | Date | undefined) => {
    setContent((d) => ({ ...d, [fieldKey]: fieldValue }));
  };

  const validate = () => {
    setFormErrors({});
    if (!validator) return true;
    const { valid, errors } = validator(content);
    if (!valid) {
      setFormErrors(errors);
      setIsLoading(false);
    }
    return valid;
  };

  const submit = async () => {
    if (isLoading) return false;
    setIsLoading(true);
    const valid = validate();
    if (!valid) return false;
    const data = await submitter(content);
    if (responseHandler) responseHandler(data);
    setIsLoading(false);
    return true;
  };

  return {
    updateField, submit, errors: formErrors, isLoading, initialize: setContent, values: content,
  };
}

export default useForm;
