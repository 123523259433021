import React, { useEffect, useState } from 'react';
import useQuery from '../../hooks/useQuery';
import paymentService from '../../services/paymentService';
import styles from '../../styles/app.module.css';
import PaymentSuccess from './PaymentSuccess';
import PaymentFailed from './PaymentFailed';
import { DogWithParents } from '../../types';

function PaymentRedirect() {
  const query = useQuery();
  const orderNumber = query.get('ORDER_NUMBER');
  const [loading, setLoading] = useState(true);
  const [isPaymentAccepted, setIsPaymentAccepted] = useState(true);
  const [paymentError, setPaymentError] = useState('');
  const [dogs, setDogs] = useState<DogWithParents[]>([]);

  useEffect(() => {
    const validatePayment = async () => {
      setLoading(true);
      if (orderNumber) {
        const {
          paymentAccepted,
          error,
          updatedDogs,
        } = await paymentService.validatePayment(orderNumber);
        setIsPaymentAccepted(paymentAccepted);
        if (error) setPaymentError(error);
        else setDogs(updatedDogs);
      }
      setLoading(false);
    };
    validatePayment();
  }, []);

  const renderPaymentInfo = () => (isPaymentAccepted ? (
    <PaymentSuccess dogs={dogs} />
  ) : (
    <PaymentFailed error={paymentError} />
  ));

  return (
    <div className={styles.contentWrapper}>
      { loading ? (
        <div className={styles.searchLoadingDiv}>
          <div className={styles.loadingIcon} />
          <p>Odotetaan vastausta...</p>
        </div>
      ) : (
        renderPaymentInfo()
      )}
    </div>
  );
}

export default PaymentRedirect;
