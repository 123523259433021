import React from 'react';
import { MdOutlineSmsFailed } from 'react-icons/md';
import styles from '../../styles/app.module.css';
import SaveButton from '../misc/SaveButton';

function PaymentFailed({ error }: { error: string }) {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'start',
      width: '100%',
      gap: '20px',
      marginTop: 40,
    }}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '600px',
        backgroundColor: 'rgb(245, 245, 245)',
        borderRadius: '10px',
        border: '1px solid rgb(200, 200, 200)',
        marginTop: 40,
      }}
      >
        <h2
          className={styles.pageHeader}
          style={{
            display: 'flex',
            fontSize: 20,
            padding: 20,
            background: 'white',
            margin: 0,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottom: '1px solid rgb(200, 200, 200)',
          }}
        >
          <MdOutlineSmsFailed
            className={styles.doneIcon}
            style={{ width: 30, height: 30, margin: 0 }}
          />
          <h2 className={styles.pageHeader} style={{ margin: 0, marginLeft: 30, fontSize: 20 }}>
            Maksu epäonnistui
          </h2>
        </h2>
        <div style={{
          padding: 20,
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
          justifyContent: 'start',
          minHeight: 100,
        }}
        >
          {error && (<p>{error}</p>)}
          <p>Ota tarvittaessa yhteys rekisterinpitäjään.</p>
        </div>
        <div
          style={{
            fontSize: 20,
            padding: 20,
            background: 'white',
            margin: 0,
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            borderTop: '1px solid rgb(200, 200, 200)',
          }}
        >
          <SaveButton
            text="Siirry takaisin etusivulle"
            onClick={() => { window.location.href = '/'; }}
            variant="contained"
            size="large"
          />
        </div>
      </div>
    </div>
  );
}

export default PaymentFailed;
