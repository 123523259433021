import React from 'react';
import styles from '../../../styles/dogForm.module.css';
import { DogWithParents } from '../../../types';

function LightRegInfoDiv({ dog }: { dog: DogWithParents }) {
  const {
    name, secondReg, sire, dam, birthday,
  } = dog;
  return (
    <div className={styles.lightRegInfoDiv}>
      <div className={styles.row}>
        <div className={styles.col}>
          <p className={styles.label}>Nimi</p>
          <p className={styles.value}>{ name }</p>
        </div>
        <div className={styles.col}>
          <p className={styles.label}>Rekisterinumero</p>
          <p className={styles.value}>{ secondReg }</p>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <p className={styles.label}>Isä</p>
          <p className={styles.value}>{ sire?.name || 'Ei tiedossa' }</p>
        </div>
        <div className={styles.col}>
          <p className={styles.label}>Emä</p>
          <p className={styles.value}>{ dam?.name || 'Ei tiedossa' }</p>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <p className={styles.label}>Syntymäaika</p>
          <p className={styles.value}>
            {birthday ? new Date(birthday)?.toLocaleDateString() : 'Ei tiedossa'}
          </p>
        </div>
      </div>
    </div>
  );
}

export default LightRegInfoDiv;
