import React from 'react';
import styles from '../../styles/app.module.css';

function PaymentTerms() {
  return (
    <div className={styles.plainText}>
      <br />
      <br />
      <h2>Maksuehdot</h2>
      <div className={styles.infoGroup}>
        <h3>Toimitusehdot</h3>
        <p>
          YHDISTYS 3037224-3 (jäljempänä verkkokauppa) myy tuotteita yksityishenkilöille Suomeen.
          Pidätämme oikeuden toimitusehtojen ja hintojen muutoksiin.
          Tuotteiden hinnat sisältävät arvonlisäveron.
        </p>
      </div>

      <div className={styles.infoGroup}>
        <h3>Verkkokaupan yhteystiedot</h3>
        <p>
          Sähköposti: spkytietokanta@gmail.com
          <br />
          <br />
          Puhelin: 040 8672426
          <br />
          <br />
          Postiosoite: Suitianummentie 141b, 02750 Siuntio
        </p>
      </div>

      <div className={styles.infoGroup}>
        <h3>Tilaaminen</h3>
        <p>
          Tilattavat tuotteet (koirien rekisteröinti tai kilpailuun ilmoittautuminen) valitaan
          verkkosivuilla täyttämällä tilauksen tiedot ja siirtymällä maksusivulle.
          Tilaus lähetetään maksamalla tuotteet verkkokaupan kassatoiminnossa.
          Tehdessäsi tilauksen hyväksyt nämä toimitusehdot, tuotteiden hinnat sekä palvelumaksun.
          Tilauksesta lähetetään tilausvahvistus sähköpostitse.
          Tilausvahvistuksesta ilmenevät tilatut tuotteet.
        </p>
      </div>

      <div className={styles.infoGroup}>
        <h3>Maksaminen</h3>
        <p>
          Verkkokaupan maksuvälittäjänä toimii Visma Pay (Visma Payments Oy, y-tunnus 2486559-4),
          joka on rekisteröity Finanssivalvonnan ylläpitämään maksulaitosrekisteriin.
          Maksamiseen siirrytään Visma Payn verkkopalvelun kautta ja tiliotteella ja laskulla
          maksun saajana näkyy Visma Pay tai Visma Payments Oy. Visma Pay välittää maksut
          verkkokauppiaalle. Maksaminen on turvallista, sillä kaikki maksutapahtumaa koskevat
          tiedot välitetään salattua yhteyttä käyttäen niin ettei kukaan ulkopuolinen taho näe
          maksutapahtuman tietoja.
          <br />
          <br />
          Kauppa syntyy verkkokaupan asiakkaan ja verkkokaupan välille.
          Verkkokaupan vastuulla ovat kaikki kauppaan liittyvät velvoitteet.
          <br />
          <br />
          Lue lisää Visma Paysta: https://www.vismapay.fi/
        </p>
      </div>

      <div className={styles.infoGroup}>
        <h3>Maksutavat</h3>
        <p>
          Visma Pay -palvelun kautta voit maksaa verkkopankkitunnuksilla, lompakolla tai
          maksukorteilla (credit/debit).
          Käytettävissä ovat seuraavat maksutavat: Osuuspankki, Nordea, Danske Bank,
          Oma Säästöpankki, Säästöpankki, Aktia, Paikallisosuuspankit, S-Pankki,
          Handelsbanken, Ålandsbanken, MobilePay, Pivo, Visa-, Visa Debit-, Visa Electron-,
          MasterCard- ja Debit MasterCard -kortit.
          <br />
          <br />
          MobilePay: Voit maksaa MobilePay-lompakollasi mikäli olet sallinut
          verkkokaupoissa maksamisen sovelluksen asetuksista. MobilePay-lompakolla
          suoritetut maksut veloitetaan lompakkoon liitetyltä maksukortilta.
          Mikäli maksun veloittaminen maksukortilta epäonnistuu, MobilePay-lompakolla
          maksaminen ei ole mahdollista verkkokaupassa.
          <br />
          <br />
          Pivo: Käyttöehdot ovat tarjolla Pivon sivuilla: https://pivo.fi/kayttoehdot/pivon-kayttoehdot/
        </p>
      </div>

      <div className={styles.infoGroup}>
        <h3>Visma Pay -maksupalvelun yhteystiedot</h3>
        <p>
          Visma Payments Oy (Y-tunnus 2486559-4)
          <br />
          <br />
          Sähköposti: helpdesk@vismapay.com
          <br />
          <br />
          Puhelin: 09 315 42 037 (arkisin klo 9-16)
          <br />
          <br />
          Postiosoite: Brahenkatu 4, 53100 Lappeenranta
        </p>
      </div>

      <div className={styles.infoGroup}>
        <h3>Tilauksen peruuttaminen, virhevastuu ja reklamaatiot</h3>
        <p>
          Virheellisen rekisteröinnin / kilpailuun ilmoittautumisen osalta ota yhteys
          rekisterinpitäjään / kilpailuvastaavaan maksun palautusta varten.
          Palvelumaksua ei palauteta. Yhteystiedot löytyvät SPKY:n sivuilta www.spky.fi
        </p>
      </div>

    </div>
  );
}

export default PaymentTerms;
