import React, { useEffect, useState } from 'react';
import { FiCheckCircle } from 'react-icons/fi';
import { DogWithParents } from '../../../types';
import viewStyles from '../../../styles/viewDog.module.css';
import appStyles from '../../../styles/app.module.css';
import SaveButton from '../../misc/SaveButton';
import registerService from '../../../services/registerService';

function DogInfo({ dog }: { dog: DogWithParents }) {
  return (
    <div className={viewStyles.dogTableLargeRow}>
      <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
        <p className={viewStyles.dogInfoTh}>Koiran virallinen nimi</p>
        <p className={viewStyles.dogInfoTd}>{dog.name}</p>
      </div>
      <div className={`${viewStyles.dogTableRow} ${viewStyles.alterBg}`}>
        <p className={viewStyles.dogInfoTh}>Rekisterinumerot</p>
        <p className={viewStyles.dogInfoTd}>{dog.secondReg ?? '-'}</p>
      </div>
    </div>
  );
}

function DogCreated({ dogId }: { dogId: string }) {
  const [loading, setLoading] = useState(true);
  const [dog, setDog] = useState<DogWithParents | undefined>(undefined);
  useEffect(() => {
    const fetchDog = async () => {
      const response = await registerService.getDog(dogId, ['name', 'secondReg']);
      setDog(response.data);
      setLoading(false);
    };
    fetchDog();
  }, [dogId]);

  if (loading || !dog) {
    return (
      <div className={appStyles.searchLoadingDiv}>
        <div className={appStyles.loadingIcon} />
        <p>Odotetaan vastausta...</p>
      </div>
    );
  }

  return (
    <div className={appStyles.contentWrapper}>
      <div style={{
        display: 'flex',
        justifyContent: 'start',
        width: '100%',
        gap: '20px',
        marginTop: 40,
      }}
      >
        <FiCheckCircle className={appStyles.doneIcon} />
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        >
          <h2 className={appStyles.pageHeader} style={{ margin: 0 }}>
            Sukupuukoira luotu onnistuneesti
          </h2>
        </div>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '600px',
        backgroundColor: 'rgb(245, 245, 245)',
        borderRadius: '10px',
        border: '1px solid rgb(200, 200, 200)',
        marginTop: 40,
      }}
      >
        <h2
          className={appStyles.pageHeader}
          style={{
            fontSize: 20,
            padding: 20,
            background: 'white',
            margin: 0,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottom: '1px solid rgb(200, 200, 200)',
          }}
        >
          Luotu koira
        </h2>
        <div style={{
          padding: 20,
          paddingTop: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
        }}
        >
          <DogInfo dog={dog} key={dog.id} />
        </div>
        <div
          style={{
            fontSize: 20,
            padding: 20,
            background: 'white',
            margin: 0,
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            borderTop: '1px solid rgb(200, 200, 200)',
          }}
        >
          <SaveButton
            text="Siirry takaisin aloitussivulle"
            onClick={() => { window.location.href = '/register'; }}
            variant="contained"
            size="large"
          />
        </div>
      </div>
    </div>
  );
}

export default DogCreated;
