const REGISTER_TYPES = [
  'Perusrekisteri',
  'Jalostusrekisteri',
  'Muu rekisteri',
] as const;

const BREED_TYPES = ['Bordercollie', 'Working kelpie', 'Muu'] as const;
const GENDER_TYPES = ['Uros', 'Narttu'] as const;

const REDIRECT_URLS = {
  success: '/success',
  error: '/error',
};

export default {
  REGISTER_TYPES,
  BREED_TYPES,
  GENDER_TYPES,
  REDIRECT_URLS,
};
