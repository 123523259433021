import React, { useEffect, useState } from 'react';
import styles from '../../../styles/dogForm.module.css';
import appStyles from '../../../styles/app.module.css';
import useNotification from '../../../hooks/useNotification';
import Notification from '../../misc/Notification';
import useForm from '../../../hooks/useForm';
import dogValidators from '../../../validators/dogValidators';
import registerService from '../../../services/registerService';
import { Breed, DogWithParents, NewDog } from '../../../types';
import TextInput from '../../inputs/TextInput';
import SelectInput from '../../inputs/SelectInput';
import constants from '../../../utils/constants';
import DogAutocompleteSearch from '../../inputs/DogAutocompleteSearch';
import LightRegInfoDiv from '../misc/LightRegInfoDiv';
import requestFields from '../../../utils/requestFields';
import SaveButton from '../../misc/SaveButton';
import DateInput from '../../inputs/DateInput';
import ConfirmPurchase from '../../payments/ConfirmPurchase';

function CompleteLightRegistration() {
  const { notification, showNotification } = useNotification();
  const [selectedDogId, setSelectedDogId] = useState<string | undefined>(undefined);
  // sukupuurek dogs don't have birthday set, so we need to ask it
  const [shouldAskBirthday, setShouldAskBirthDay] = useState<boolean>(false);

  const urlParams = new URLSearchParams(window.location.search);
  const [submitted, setSubmitted] = useState<boolean>(urlParams.get('submitted') === 'true');

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const submittedParam = searchParams.get('submitted');
    const isSubmitted = submittedParam === 'true';
    setSubmitted(isSubmitted);
  }, [urlParams]);

  const setSubmittedWithUrl = (value: boolean) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('submitted', value.toString());
    setSubmitted(value);
    if (value) {
      window.history.pushState({}, '', `${window.location.pathname}?${searchParams}`);
    } else {
      window.history.replaceState({}, '', window.location.pathname);
    }
  };

  const {
    updateField,
    submit,
    errors,
    values,
    initialize,
    isLoading,
  } = useForm({
    validator: dogValidators.validateCompleteLightReg,
    submitter: async () => {
      if (!selectedDogId) {
        showNotification('Valitse koira jonka tietoja haluat täydentää');
        return;
      }
      // Using a slight delay to indicate state change
      // to the user before the next step
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 1500));
      setSubmittedWithUrl(true);
    },
  });

  const updateDogField = (fieldKey: keyof NewDog) => updateField(fieldKey);

  useEffect(() => {
    if (!selectedDogId) return;
    const fetchDog = async (dogId: string) => {
      const res = await registerService.getDog(dogId, requestFields.LIGHT_REG_FIELDS);
      const { birthday } = res.data;
      if (birthday) setShouldAskBirthDay(false);
      else setShouldAskBirthDay(true);
      initialize({
        ...res.data,
        damId: res.data.dam?.id,
        sireId: res.data.sire?.id,
      });
    };
    if (selectedDogId) fetchDog(selectedDogId);
  }, [selectedDogId]);

  useEffect(() => {
    const hasError = Object.values(errors).reduce((p, n) => p || n, false);
    if (hasError) showNotification('Tarkista puuttuvat kentät');
  }, [errors]);

  if (submitted && selectedDogId) {
    const { dam, sire, ...rest } = values as DogWithParents & NewDog;
    const dog = {
      ...rest,
      registerType: 'Perusrekisteri',
    } as NewDog;
    return (
      <ConfirmPurchase
        registrationData={{
          type: 'COMPLETE_LIGHT',
          data: {
            dog,
            selectedDogId,
          },
        }}
        onClose={() => setSubmittedWithUrl(false)}
      />
    );
  }

  // Used to restrict parent search to only Bordercollie and Kelpie,
  // if the registered dog is one of them
  const parentSearchBreed: Breed | undefined = ['Bordercollie', 'Working kelpie'].includes(values.breed)
    ? values.breed
    : undefined;

  return (
    <div className={styles.formWrapper}>
      <form className={styles.form}>
        {notification && <Notification message={notification} type="error" /> }
        <div className={styles.formGroup}>
          <h2 className={appStyles.pageHeader}>Rekisteröi kannassa jo oleva koira</h2>
          <div style={{ marginBottom: 20 }}>
            <span style={{ fontSize: 14, color: '#333' }}>
              Hae rekisteröitavä koira nimen tai rekisterinumeron perusteella,
              täydennä koiran ja omistajan tiedot ja siirry maksamaan.
              <br />
              <br />
              Toimita tarvittavat liitteet rekisterinpitäjälle.
              <br />
              <br />
              Rekisteritodistus toimitetaan postitse. Huom! Koira näkyy
              tietokannassa vasta kun rekisteröintipyyntö on hyväksytty.
            </span>
          </div>

          <DogAutocompleteSearch
            className="complete-light-reg-search"
            placeholder="Hae nimellä tei rek.no:lla"
            label="Hae täydennettävä koira"
            setValue={setSelectedDogId}
            searchOptions={{ registerType: ['Muu rekisteri'] }}
          />
          { selectedDogId && (
            <LightRegInfoDiv dog={values as DogWithParents} />
          ) }
          <SelectInput
            label="Rotu"
            options={constants.BREED_TYPES.map((breed) => [breed, breed])}
            setValue={updateDogField('breed')}
            error={errors.breed}
            defaultValue={values.breed ?? undefined}
            className="breed-input"
          />
          {
            shouldAskBirthday && (
              <DateInput
                setValue={updateDogField('birthday')}
                defaultValue={values.birthday}
                label="Syntymäaika *"
                error={errors.birthday}
              />
            )
          }
          <TextInput
            required
            label="Kasvattajan nimi"
            placeholder="Etunimi Sukunimi"
            setValue={updateDogField('breeder')}
            error={errors.breeder}
            defaultValue={values.breeder}
            className="breeder-name-input"
          />
          <TextInput
            label="Kasvattajan paikkakunta"
            setValue={updateDogField('breederAddress')}
            error={errors.breederAddress}
            defaultValue={values.breederAddress}
            className="breeder-address-input"
          />
          <TextInput
            label="Väri"
            setValue={updateDogField('color')}
            error={errors.color}
            defaultValue={values.color}
            className="color-input"
          />
          <SelectInput
            label="Sukupuoli"
            options={constants.GENDER_TYPES.map((gender) => [gender, gender])}
            setValue={updateDogField('gender')}
            error={errors.gender}
            defaultValue={values.gender ?? undefined}
            className="gender-input"
          />
          <TextInput
            required
            label="Mikrosirunumero"
            info="Koiran tunnusmerkintä tai mikrosirun numero"
            setValue={updateDogField('idtm')}
            error={errors.idtm}
            defaultValue={values.idtm}
            className="idtm-input"
          />
          <DogAutocompleteSearch
            defaultValue={values.sireId}
            setValue={updateDogField('sireId')}
            label="Isä *"
            placeholder="Isä"
            error={errors.sireId}
            searchOptions={{ gender: 'Uros', breed: parentSearchBreed }}
            className="sire-input"
          />
          <DogAutocompleteSearch
            defaultValue={values.damId}
            setValue={updateDogField('damId')}
            label="Emä *"
            placeholder="Emä"
            error={errors.damId}
            searchOptions={{ gender: 'Narttu', breed: parentSearchBreed }}
            className="dam-input"
          />
          <TextInput
            label="Lisätiedot (ei-julkinen viesti rekisterinpitäjälle)"
            placeholder="Esim. lonkka-, selkä-, olka- ja silmätulokset."
            setValue={updateDogField('otherInfoClassified')}
            error={errors.otherInfoClassified}
            defaultValue={values.otherInfoClassified}
            textArea
            className="other-info-classified-input"
          />
        </div>
        <div className={`${styles.formGroup} ${styles.altFormGroup}`}>
          <div className={styles.altFormContent}>
            <h2 className={appStyles.pageHeader}>Omistajan tiedot</h2>
            <TextInput
              white
              required
              label="Nimi"
              placeholder="Etunimi Sukunimi"
              setValue={updateDogField('ownerName')}
              error={errors.ownerName}
              defaultValue={values.ownerName}
              className="owner-name-input"
            />
            <TextInput
              white
              required
              label="Sähköposti"
              placeholder="Sähköposti"
              setValue={updateDogField('ownerEmail')}
              error={errors.ownerEmail}
              defaultValue={values.ownerEmail}
              className="owner-email-input"
            />
            <TextInput
              white
              label="Puhelinnumero"
              placeholder="Puhelinnumero"
              setValue={updateDogField('ownerPhone')}
              error={errors.ownerPhone}
              defaultValue={values.ownerPhone}
              className="owner-phone-input"
            />
            <TextInput
              white
              label="Osoite"
              placeholder="Katuosoite, postinumero"
              setValue={updateDogField('ownerAddress')}
              error={errors.ownerAddress}
              defaultValue={values.ownerAddress}
              className="owner-address-input"
            />
            <SaveButton
              text="Jatka tietojen tarkistukseen"
              size="large"
              variant="contained"
              onClick={submit}
              isLoading={isLoading}
              className="submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

CompleteLightRegistration.defaultProps = {
  dogId: undefined,
};

export default CompleteLightRegistration;
