import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { ChevronLeft, CloseOutlined } from '@mui/icons-material';
import styles from '../../styles/dogForm.module.css';
import appStyles from '../../styles/app.module.css';
import registerService from '../../services/registerService';
import {
  LitterInfo, NewDog, NewFamilyTreeReg, PuppyInfo,
} from '../../types';
import viewStyles from '../../styles/viewDog.module.css';
import SaveButton from '../misc/SaveButton';
import useNotification from '../../hooks/useNotification';
import Notification from '../misc/Notification';
import products from '../../utils/products';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ConfirmBasicDogData from './ConfirmBasicDogData';
import ConfirmLitterData from './ConfirmLitterData';
import ConfirmOtherDogData from './ConfirmOtherDogData';

type BasicRegistration = {
  type: 'BASIC',
  data: NewDog,
};

type CompleteLightRegistration = {
  type: 'COMPLETE_LIGHT',
  data: {
    dog: NewDog,
    selectedDogId: string,
  }
};

type LitterRegistration = {
  type: 'PUPPY_REG'
  data: {
    puppies: PuppyInfo[],
    litter: LitterInfo,
  },
};

type OtherRegistration = {
  type: 'OTHER',
  data: NewFamilyTreeReg,
};

type RegistrationData = BasicRegistration
| LitterRegistration
| CompleteLightRegistration
| OtherRegistration;

const getProduct = (type: RegistrationData['type']) => {
  switch (type) {
    case 'BASIC': return products.DOG_REG;
    case 'COMPLETE_LIGHT': return products.DOG_REG;
    case 'PUPPY_REG': return products.PUPPY_REG;
    case 'OTHER': return products.FAMILYTREE_DOG_REG;
    default: return products.DOG_REG;
  }
};

const submitLitter = async (registrationData: LitterRegistration) => {
  const { puppies, litter } = registrationData.data;
  const data = puppies.map((p) => ({ ...p, ...litter }));
  const res = await registerService.createLitter(data);
  return res;
};

const submitDog = async (registrationData: BasicRegistration) => {
  const dog = registrationData.data;
  const response = await registerService.createDog(dog);
  return response;
};

const submitCompleteLightRegistration = async (registrationData: CompleteLightRegistration) => {
  const { dog, selectedDogId } = registrationData.data;
  const res = registerService.completeLightRegistration(dog, selectedDogId);
  return res;
};

const submitOtherRegistration = async (registrationData: OtherRegistration) => {
  const response = await registerService.createOtherRegisterdog('Muu rekisteri')(registrationData.data);
  return response;
};

const submitRegistration = async (registrationData: RegistrationData) => {
  switch (registrationData.type) {
    case 'BASIC': return submitDog(registrationData);
    case 'PUPPY_REG': return submitLitter(registrationData);
    case 'COMPLETE_LIGHT': return submitCompleteLightRegistration(registrationData);
    case 'OTHER': return submitOtherRegistration(registrationData);
    default: return submitDog(registrationData);
  }
};

function ConfirmPurchase({
  registrationData,
  onClose,
}: {
  registrationData: RegistrationData,
  onClose: () => void,
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const { notification, showNotification } = useNotification();

  const product = getProduct(registrationData.type);

  const handleSubmit = async () => {
    setLoading(true);

    const response = await submitRegistration(registrationData);

    const { data, success, error } = response;
    if (success) {
      const { redirectUrl } = data;
      window.location.href = redirectUrl;
    } else {
      showNotification(error, 'error');
      setLoading(false);
    }
  };

  const windowDims = useWindowDimensions();
  const isMobile = windowDims.width < 900;

  // always scroll to top when component is rendered
  useEffect(() => window.scrollTo(0, 0), []);

  const isBasicOrLight = registrationData.type === 'BASIC' || registrationData.type === 'COMPLETE_LIGHT' || registrationData.type === 'OTHER';

  const productCount = isBasicOrLight ? 1 : registrationData.data.puppies.length;
  const totalPrice = registrationData.type === 'OTHER'
    ? 0
    : (product.price * productCount) + products.COMISSION.price;

  return (
    <div className={viewStyles.wrapper}>
      <div className={styles.formWrapper}>
        {notification && <Notification message={notification} type="error" /> }
        <div style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          position: 'relative',
        }}
        >
          <div className={viewStyles.infoTable}>
            {registrationData.type === 'BASIC' && (
              <ConfirmBasicDogData dog={registrationData.data} />
            )}
            {registrationData.type === 'OTHER' && (
            <ConfirmOtherDogData dog={registrationData.data} />
            )}
            {registrationData.type === 'COMPLETE_LIGHT' && (
              <ConfirmBasicDogData dog={registrationData.data.dog} />
            )}
            {(registrationData.type === 'PUPPY_REG') && (
              <ConfirmLitterData
                litter={registrationData.data.litter}
                puppies={registrationData.data.puppies}
              />
            )}
          </div>

          <div
            className={`${styles.formGroup} ${styles.altFormGroup}`}
            style={{
              marginTop: 0,
              padding: 0,
              height: 'auto',
              maxHeight: isMobile ? 'none' : 600,
              maxWidth: isMobile ? 600 : 'none',
            }}
          >
            <div
              className={styles.altFormContent}
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                height: '100%',
                paddingBottom: '20px',
                maxWidth: 'none',
              }}
            >
              <h1 className={appStyles.pageHeader} style={{ padding: '0 20px' }}>Hinta</h1>
              <div style={{
                display: 'grid',
                // 2 columns, each row takes 100% of the width
                gridTemplateColumns: '1fr',
                marginBottom: '20px',
              }}
              >
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '0 20px',
                  borderTop: '1px solid #e0e0e0',
                }}
                >
                  <p className={styles.formLabel}><b>Tuote</b></p>
                  <p className={styles.formValue}><b>Hinta / kpl</b></p>
                </div>

                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  background: '#fff',
                  padding: '0 20px',
                  borderTop: '1px solid #e0e0e0',
                }}
                >
                  <p className={styles.formLabel}>
                    {productCount}
                    <CloseOutlined fontSize="small" style={{ margin: '0 4px -2px 4px', fontSize: 14 }} />
                    {product.title}
                  </p>
                  <p className={styles.formValue}>
                    {product.price.toFixed(2)}
                    €
                  </p>
                </div>

                {registrationData.type !== 'OTHER' && (
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    background: '#fff',
                    padding: '0 20px',
                    borderTop: '1px solid #e0e0e0',
                  }}
                  >
                    <p className={styles.formLabel}>{products.COMISSION.title}</p>
                    <p className={styles.formValue}>
                      {products.COMISSION.price.toFixed(2)}
                      €
                    </p>
                  </div>
                )}

                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  background: '#fff',
                  padding: '0 20px',
                  borderTop: '1px solid #333',
                  borderBottom: '1px solid #e0e0e0',
                }}
                >
                  <p className={styles.formLabel}><b>Yhteensä</b></p>
                  <p className={styles.formValue}>
                    <b>
                      {totalPrice.toFixed(2)}
                      €
                    </b>
                  </p>
                </div>
                {registrationData.type !== 'OTHER'
                    && (
                    <p style={{
                      padding: '0 20px',
                      marginTop: 20,
                      fontSize: '15px',
                      color: '#333',
                    }}
                    >
                      Sinut ohjataan Visma Pay -maksupalveluun suorittamaan rekisteröintimaksu.
                      Onnistuneen maksun jälkeen saat sähköpostiisi vahvistuksen rekisteröinnistä.
                      <br />
                      <p>
                        Siirtymällä maksamaan hyväksyt palvelun
                        {' '}
                        <a
                          href="/payment-terms"
                          target="_blank"
                          rel="noreferrer"
                        >
                          maksuehdot
                        </a>
                        {' '}
                        ja
                        {' '}
                        <a
                          href="/terms"
                          target="_blank"
                          rel="noreferrer"
                        >
                          tietosuojaselosteen.
                        </a>
                      </p>
                    </p>
                    )}
              </div>
              <div style={{
                padding: '0 20px',
                marginTop: 40,
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                gap: '20px',
              }}
              >
                <Button
                  startIcon={
                    <ChevronLeft />
                  }
                  variant="outlined"
                  onClick={onClose}
                  style={{ width: '100%' }}
                >
                  Takaisin muokkaukseen
                </Button>
                <SaveButton
                  text={registrationData.type === 'OTHER' ? 'Lähetä' : 'Siirry maksamaan'}
                  size="large"
                  variant="contained"
                  onClick={handleSubmit}
                  isLoading={loading}
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmPurchase;
