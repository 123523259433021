import { NewDog } from '../types';

const LIGHT_REG_FIELDS: (keyof NewDog)[] = ['name', 'birthday', 'secondReg', 'breed', 'color', 'gender'];

const PDF_FIELDS = ['breed', 'color', 'birthday', 'gender', 'name', 'idtm', 'spkyReg', 'ownerName', 'ownerAddress', 'ownerAddress', 'ownerPhone', 'breeder', 'otherInfo', 'secondReg', 'cea'];

export default {
  LIGHT_REG_FIELDS,
  PDF_FIELDS,
};
