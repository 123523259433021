import {
  ValidationResult,
} from '../types';
import checks from './checks';

const { checkIsEmpty, checkIsEmail } = checks;

export type LoginUserData = {
  email: string,
  password: string
};

export type SignUpUserData = {
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  phone: string
};

type PasswordResetData = {
  email: string
};

type NewPasswordData = {
  password: string
  passwordRepeat: string
};

const validateLoginUser = (data: LoginUserData): ValidationResult => {
  const errors = {
    email: !checkIsEmail(data.email),
    password: !checkIsEmpty(data.password),
  };
  const hasError = Object.values(errors).reduce((prevVal, val) => val || prevVal, false);
  return {
    valid: !hasError,
    errors,
  };
};

const validateSignUpUser = (data: SignUpUserData): ValidationResult => {
  const errors = {
    email: !checkIsEmail(data.email),
    password: !checkIsEmpty(data.password),
    firstName: !checkIsEmpty(data.firstName),
    lastName: !checkIsEmpty(data.lastName),
    phone: !checkIsEmpty(data.phone),
  };
  const hasError = Object.values(errors).reduce((prevVal, val) => val || prevVal, false);
  return {
    valid: !hasError,
    errors,
  };
};

const validatePasswordReset = (data: PasswordResetData): ValidationResult => {
  const errors = {
    email: !checkIsEmail(data.email),
  };
  const hasError = Object.values(errors).reduce((prevVal, val) => val || prevVal, false);
  return {
    valid: !hasError,
    errors,
  };
};

const validateNewPassword = (data: NewPasswordData): ValidationResult => {
  const areEqual = data.password === data.passwordRepeat;
  const atleastFourChars = data.password.length >= 4;
  const errors = {
    password: !checkIsEmpty(data.password) || !areEqual || !atleastFourChars,
    passwordRepeat: !checkIsEmpty(data.passwordRepeat) || !areEqual,
  };
  const hasError = Object.values(errors).reduce((prevVal, val) => val || prevVal, false);
  return {
    valid: !hasError,
    errors,
  };
};

export default {
  validateLoginUser,
  validateSignUpUser,
  validatePasswordReset,
  validateNewPassword,
};
