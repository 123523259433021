import React, { useEffect, useState } from 'react';
import {
  DataGrid,
} from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import styles from '../../styles/admin.module.css';
import useNotification from '../../hooks/useNotification';
import Notification from '../misc/Notification';
import dataGridCols from './userDataGridCols';
import { User } from '../../types';
import authService from '../../services/authService';

function DeleteUserForm({
  user, onSubmit, onCancel,
}: {
  user: User,
  onSubmit: (dogId: string) => void,
  onCancel: () => void }) {
  return (
    <div style={{
      position: 'fixed',
      width: '500px',
      height: 'auto',
      top: 'calc(50% - 150px)',
      left: 'calc(50% - 250px)',
      background: 'white',
      padding: '20px',
      borderRadius: '5px',
      boxShadow: '0 0 10px rgba(0,0,0,0.5)',
      textAlign: 'center',
      zIndex: 1000,
    }}
    >
      <Typography style={{ marginBottom: '20px' }}>
        Oletko varma, että haluat poistaa käyttäjän
        <br />
        <b>
          {user.firstName}
          {' '}
          {user.lastName}
        </b>
        {' '}
        (
        {user.email}
        )
        ?
      </Typography>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        justifyContent: 'center',
      }}
      >
        <Button
          onClick={() => onSubmit(user.id.toString())}
          variant="contained"
          color="error"
        >
          Poista
        </Button>
        <Button
          onClick={onCancel}
          variant="outlined"
        >
          Peruuta
        </Button>
      </div>
    </div>
  );
}

function UserDashboard() {
  const [rows, setRows] = useState<User[]>([]);
  const { notification, notificationType, showNotification } = useNotification();
  const [deleteForm, setDeleteForm] = useState<{ user: User } | null>(null);
  const [fieldToEdit, setFieldToEdit] = useState<keyof User | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, success, error } = await authService.getAllUsers();
        if (success) {
          setRows(data);
        } else {
          showNotification(`Käyttäjien hakeminen epäonnistui: ${error}`);
        }
      } catch (error) {
        showNotification(`Käyttäjien hakeminen epäonnistui: ${error}`);
      }
    };
    fetchData();
  }, []);

  const handleCellEditStop = React.useCallback(
    async (newRow: User, oldRow: User, field: keyof User | null): Promise<User> => {
      if (field === null) return oldRow;
      setFieldToEdit(null);
      const newValue = newRow[field];
      const { id } = newRow;
      try {
        const updatedData = { [field]: newValue };
        const user = rows.find((d) => d.id === id);
        const key = field as keyof User;
        if ((user === undefined) || user[key] === newValue) return oldRow;
        const { data, success, error } = await authService.updateUser(
          updatedData,
          id.toString(),
        );
        if (success) {
          showNotification('Käyttäjä päivitetty onnistuneesti');
          setRows((prev) => prev.map((row) => (row.id === id ? { ...row, ...data } : row)));
          return newRow;
        }
        showNotification(`Käyttäjän päivittäminen epäonnistui: ${error}`);
        return oldRow;
      } catch (error) {
        showNotification(`Käyttäjän päivittäminen epäonnistui: ${error}`);
        // Restore the row in case of error
        setRows((prev) => [...prev]);
        return oldRow;
      }
    },
    [rows],
  );

  const deleteUser = async (userId: string) => {
    const result = await authService.deleteUser(userId);
    if (result.success) {
      setRows((prev) => prev.filter((row) => row.id.toString() !== userId));
      showNotification('Käyttäjä poistettu onnistuneesti');
    } else {
      showNotification(`Käyttäjä poistaminen epäonnistui: ${result.error}`);
    }
    setDeleteForm(null);
  };

  return (
    <div className={styles.dataGridContainer}>
      {notification && <Notification message={notification} type={notificationType} />}
      <DataGrid
        paginationMode="client"
        rowCount={rows.length}
        rows={rows}
        loading={rows.length === 0}
        columns={dataGridCols.columns}
        rowHeight={80}
        onCellEditStart={(params) => setFieldToEdit(params.field as keyof User)}
        processRowUpdate={(newRow, oldRow) => handleCellEditStop(newRow, oldRow, fieldToEdit)}
        onCellClick={(params) => {
          if (params.field === 'delete' && params.row) {
            setDeleteForm({ user: params.row });
          }
        }}
      />
      {deleteForm && (
        <DeleteUserForm
          user={deleteForm.user}
          onCancel={() => setDeleteForm(null)}
          onSubmit={deleteUser}
        />
      )}
    </div>
  );
}

export default UserDashboard;
