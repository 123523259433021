import { useState } from 'react';

const VISIBLE_TIME = 10_000;

function useNotification() {
  const [notification, setNotification] = useState<string | null>(null);
  const [notificationType, setNotificationType] = useState<'message' | 'error'>('message');

  const clearNotification = () => {
    setNotification(null);
  };

  const showNotification = (text: string, type: 'message' | 'error' = 'message') => {
    setNotification(text);
    setNotificationType(type);
    setTimeout(clearNotification, VISIBLE_TIME);
  };

  return { notification, showNotification, notificationType };
}

export default useNotification;
