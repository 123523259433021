import React, { useEffect } from 'react';
import styles from '../../styles/admin.module.css';
import Notification from '../misc/Notification';
import TextInput from '../inputs/TextInput';
import useForm from '../../hooks/useForm';
import useNotification from '../../hooks/useNotification';
import SaveButton from '../misc/SaveButton';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import userValidators from '../../validators/userValidators';
import authService from '../../services/authService';

function Login({ authenticate }: { authenticate: (token: string, type: 'admin' | 'user') => void }) {
  const { notification, showNotification, notificationType } = useNotification();
  const responseHandler = (res: any) => {
    try {
      const { success, data, error } = res;
      if (success) {
        const type = data.admin ? 'admin' : 'user';
        authenticate(data.token, type);
      } else {
        showNotification(error ?? 'Väärä sähköposti tai salasana', 'error');
      }
    } catch (e) {
      showNotification('Jokin meni pieleen', 'error');
    }
  };

  const {
    updateField, submit, errors, isLoading, values,
  } = useForm({
    validator: userValidators.validateLoginUser,
    submitter: authService.login,
    responseHandler,
  });

  useEffect(() => {
    const hasError = Object.values(errors).reduce((p, n) => p || n, false);
    if (hasError) showNotification('Tarkista puuttuvat kentät', 'error');
  }, [errors]);

  const windowDims = useWindowDimensions();
  const isMobile = windowDims.width < 600;

  return (
    <div className={styles.formWrapper}>
      {notification && <Notification message={notification} type={notificationType} /> }
      <form
        className={styles.form}
        style={{ maxWidth: 400 }}
        onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
      >
        <h3 className={styles.loginHeader}>Kirjaudu sisään</h3>
        <p>Koirien rekisteröinti ja kilpailuun ilmoittautuminen vaatii sisäänkirjaantumisen.</p>
        <TextInput
          label="Sähköposti"
          placeholder="Sähköposti"
          setValue={updateField('email')}
          defaultValue={values.email}
          error={errors.email}
          white={isMobile}
          className="email-input"
        />
        <TextInput
          label="Salasana"
          placeholder="Salasana"
          setValue={updateField('password')}
          defaultValue={values.password}
          error={errors.password}
          white={isMobile}
          type="password"
          className="password-input"
        />
        <SaveButton
          text="Kirjaudu"
          size="medium"
          variant="contained"
          onClick={submit}
          isLoading={isLoading}
          type="submit"
          className="login-button"
        />
        <p>
          Haluatko luoda uuden käyttäjän?
          <br />
          <a href="/signup">Rekisteröidy</a>
        </p>
        <p>
          Unohditko salasanasi?
          <br />
          <a href="/request-password-reset">Nollaa salasana</a>
        </p>
      </form>
    </div>
  );
}

export default Login;
