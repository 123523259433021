import React from 'react';
import styles from '../../styles/app.module.css';

function GDPR() {
  return (
    <div className={styles.plainText}>
      <br />
      <br />
      <h2>Rekisteri- ja tietosuojaseloste</h2>
      <p>
        Tämä on yhdistyslain (11 §), henkilötietolain
        (11 §) ja EU:n yleisen tietosuoja-asetuksen (GDPR)
        mukainen rekisteri- ja tietosuojaseloste. Laadittu 23.6.2020. Viimeisin muutos 28.5.2024.
      </p>

      <div className={styles.infoGroup}>
        <h3>1. Rekisterinpitäjä</h3>
        <p>
          Suomen Paimenkoirayhdistys ry (jäljempänä “SPKY”)
          <br />
          3037224-3
          <br />
          Rekisteristä vastaa SPKY:n hallitus
          <br />
          Yhteydenotot: spkytietokanta@gmail.com
        </p>
      </div>

      <div className={styles.infoGroup}>
        <h3>2. Rekisterin nimi</h3>
        <p>
          SPKY:n koirarekisteri ja kilpailutietokanta.
        </p>
      </div>
      <div className={styles.infoGroup}>
        <h3>3. Oikeusperuste ja henkilötietojen käsittelyn tarkoitus</h3>
        <p>
          EU:n yleisen tietosuoja-asetuksen mukainen oikeusperuste henkilötietojen käsittelylle on
          {' '}
          <br />
          - henkilön suostumus (rekisteröityessä) ja
          {' '}
          <br />
          - rekisterinpitäjän oikeutettu etu (esim. henkilön
          tietojen päivittäminen tämän pyynnöstä).
          <br />
          Henkilötietojen käsittelyn tarkoitus on kilpailutoiminnan
          ja -ilmoittautumisten mahdollistaminen verkossa, sekä koirarekisterin ylläpito.
          Tietoja ei käytetä automatisoituun päätöksentekoon tai profilointiin.
        </p>
      </div>
      <div className={styles.infoGroup}>
        <h3>4. Rekisterin tietosisältö</h3>
        <p>
          Rekisteriin tallennettavia tietoja ovat:
          <br />
          -henkilön nimi
          <br />
          -yhteystiedot (puhelinnumero, sähköpostiosoite, osoite)
          <br />
          -tiedot SPKY:ltä tilatuista palveluista (mm. koiran rekisteröinti,
          kilpailuun osallistuminen) ja niiden muutoksista.
          <br />
          Näitä tietoja säilytetään niin pitkään kuin rekisteri on käytössä.
        </p>
      </div>
      <div className={styles.infoGroup}>
        <h3>5. Säännönmukaiset tietolähteet</h3>
        <p>
          Rekisteriin tallennettavat tiedot saadaan henkilöasiakkaalta mm. www-
          lomakkeilla lähetetyistä viesteistä ja sähköpostitse.
        </p>
      </div>
      <div className={styles.infoGroup}>
        <h3>
          6. Tietojen säännönmukaiset luovutukset ja tietojen
          siirto EU:n tai ETA:n ulkopuolelle

        </h3>
        <p>
          Tietoja ei luovuteta säännönmukaisesti muille tahoille. Tietoja voidaan
          julkaista siltä osin kuin niin on sovittu henkilön kanssa. Tietoja voidaan
          siirtää rekisterinpitäjän toimesta myös EU:n tai ETA:n ulkopuolelle.
        </p>
      </div>
      <div className={styles.infoGroup}>
        <h3>7. Rekisterin suojauksen periaatteet</h3>
        <p>
          Rekisterin käsittelyssä noudatetaan huolellisuutta ja tietojärjestelmien
          avulla käsiteltävät tiedot suojataan asianmukaisesti. Kun rekisteritietoja
          säilytetään Internet-palvelimilla, niiden laitteiston fyysisestä ja
          digitaalisesta tietoturvasta huolehditaan asiaankuuluvasti.
          Rekisterinpitäjä huolehtii siitä, että tallennettuja tietoja sekä palvelimien
          käyttöoikeuksia ja muita henkilötietojen turvallisuuden kannalta
          kriittisiä tietoja käsitellään luottamuksellisesti ja vain niiden
          työntekijöiden toimesta, joiden työnkuvaan se kuuluu.
        </p>
      </div>
      <div className={styles.infoGroup}>
        <h3>8. Tarkastusoikeus ja oikeus vaatia tiedon korjaamista</h3>
        <p>
          Jokaisella rekisterissä olevalla henkilöllä on oikeus tarkistaa rekisteriin
          tallennetut tietonsa ja vaatia mahdollisen virheellisen tiedon
          korjaamista tai puutteellisen tiedon täydentämistä. Mikäli henkilö
          haluaa tarkistaa hänestä tallennetut tiedot tai vaatia niihin oikaisua,
          pyyntö tulee lähettää kirjallisesti rekisterinpitäjälle. Rekisterinpitäjä voi
          pyytää tarvittaessa pyynnön esittäjää todistamaan henkilöllisyytensä.
          Rekisterinpitäjä vastaa henkilölle EU:n tietosuoja-asetuksessa
          säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).
        </p>
      </div>
      <div className={styles.infoGroup}>
        <h3>9. Muut henkilötietojen käsittelyyn liittyvät oikeudet</h3>
        <p>
          Rekisterissä olevalla henkilöllä on oikeus pyytää häntä koskevien
          henkilötietojen poistamiseen rekisteristä (&quot;oikeus tulla unohdetuksi&quot;).
          Niin ikään rekisteröidyillä on muut EU:n yleisen tietosuoja-asetuksen
          mukaiset oikeudet kuten henkilötietojen käsittelyn rajoittaminen
          tietyissä tilanteissa. Pyynnöt tulee lähettää kirjallisesti

          rekisterinpitäjälle. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön
          esittäjää todistamaan henkilöllisyytensä. Rekisterinpitäjä vastaa
          henkilölle EU:n tietosuoja-asetuksessa säädetyssä ajassa
          (pääsääntöisesti kuukauden kuluessa).
        </p>
      </div>
    </div>
  );
}

export default GDPR;
